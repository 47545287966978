import React, { useState, useCallback, useMemo } from 'react';
import { Accordion } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import EditMyDay from './EditMyDay';
import xClose from "../../img/icons/clockPage/xClose.svg";
import OpenCLoseTextAreaReport from './OpenCLoseTextAreaReport';
import { checkShowDays } from './clockFunctions';

const CardDayReport = ({ item, editMode, monthData, reloadData }) => {
  console.log('CardDayReport props:', { item, editMode, monthData });
  
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = useCallback((open) => (event) => {
    console.log('toggleDrawer called with:', open);
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  }, []);

  const drawerContent = useMemo(() => {
    console.log('Rendering drawer content with:', { item, monthData });
    return (
      <Box className='boxDownModal' sx={{ width: 'auto' }} role="presentation">
        <img className='closeDown' src={xClose} alt='close' onClick={toggleDrawer(false)} />
        <EditMyDay item={item} closeMe={toggleDrawer(false)} monthData={monthData} reloadData={reloadData} />
      </Box>
    );
  }, [item, monthData, reloadData, toggleDrawer]);

  if (!checkShowDays(monthData, item)) {
    console.log('checkShowDays returned false, not rendering card');
    return null;
  }

  const commonClasses = `${item.isOk ? 'green' : 'red'} ${item.reportsCount < 1 ? 'noOpen' : ''} ${item.isAbsence ? 'isAbsence' : ''}`;
  console.log('Computed classes:', commonClasses);

  return (
    <div className='CardDayReportItem'>
      {editMode ? (
        <button className={`changeValuesBtn ${commonClasses}`} onClick={toggleDrawer(true)}>
          <ReportHeader item={item} mode="edit" />
        </button>
      ) : (
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="01">
            <Accordion.Header className={commonClasses}>
              <ReportHeader item={item} mode="view" />
            </Accordion.Header>
            <Accordion.Body>
              {item.items.map(dayItem => (
                <DayItem key={dayItem.id} dayItem={dayItem} />
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
      <Drawer
        className="reportDownModal"
        anchor="bottom"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
      >
        {drawerContent}
      </Drawer>
    </div>
  );
};

const ReportHeader = ({ item, mode }) => {
  console.log('ReportHeader props:', { item, mode });
  return (
    <div className='headerContReport'>
      <div className='colRight'>
        <p>{item.title}</p>
        <CheckStatusText item={item} />
      </div>
      <div className='leftRight'>
        <span>{mode === 'edit' ? 'דיווח ידני' : 'צפייה'}</span>
      </div>
    </div>
  );
};

const DayItem = ({ dayItem }) => {
  console.log('DayItem props:', dayItem);
  return (
    <>
      <OpenCLoseTextAreaReport dayItem={dayItem} />
      <ul className='inOutItems'>
        <TimeItem label="שעת כניסה" time={dayItem?.in} />
        <li>-</li>
        <TimeItem label="שעת יציאה" time={dayItem?.out} />
      </ul>
    </>
  );
};

const TimeItem = ({ label, time }) => {
  console.log('TimeItem props:', { label, time });
  return (
    <li>
      <span className={time ? '' : 'error'}>{label}</span>
      {time ? <p>{time}</p> : <p className='error'>--:--</p>}
    </li>
  );
};

const CheckStatusText = ({ item }) => {
  console.log('CheckStatusText props:', item);
  if (item.isAbsence) {
    return <p>קיימת סיבת העדרות: <strong>{item.isAbsence}</strong></p>;
  }
  if (item.reportsCount < 1) {
    return <p style={{color: 'red'}}>אין דיווחים</p>;
  }
  if (!item.isOk) {
    return <p style={{color: 'red'}}>חסרה כניסה / יציאה</p>;
  }
  return <p>כמות דיווחים שבוצעה: <strong>{item.reportsCount}</strong></p>;
};

export default CardDayReport;
