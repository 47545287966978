import React, { useState } from 'react'

import { filesTexts } from './fileFunctions'
import { getFromApiSherutLeumi } from './fileFunctions';


import { Grid } from '@material-ui/core';

import OpenPDFfiles from './OpenPDFfiles';
//import ReadAndSign from './ReadAndSign';

//console.log(filesTexts);

export default function IshurimDocs(props) {

  const {infoUser} = props

    console.log('infoUser > ', infoUser);
    const serviceStatus = infoUser?.SeviceStatus;



//haim 23.06.24
    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(false);

  let userJ = JSON.parse(localStorage.getItem("userData"));
    
  if(!loading && !responseData) {

        const sendObj = {

            IDNumber: userJ.IDNO,
            SessionKey: userJ.SessionKey,
        }

        getFromApiSherutLeumi('/v2/volunteer/documents/list', sendObj, setLoading, setResponseData);
  

    }
    let data = [];

    if(loading){
      return <Grid item md={12} >
      <p style={{textAlign: 'center', margin: '0 0 20px 0'}}>בטעינה ...</p>
    </Grid>
      
    }

    if(responseData ){
      data = responseData.data['DocumentsList'];
      console.log("data", data);

    }
  
  
  //const isEligibleForCertificate = infoUser?.isEligibleForCertificate;
    
    //console.log(' isEligibleForCertificate > ',  isEligibleForCertificate )

    //console.log('noContent > ', noContent )
    //const objectForm = objectsMyforms(forms);
    //let userJ = JSON.parse(localStorage.getItem("userData"));

    //console.log('objectForm >', objectForm);

  return (
    <div className='docsCont'>
        
        <Grid className='lineForm' container spacing={2}  alignItems="top" direction="row" justifyContent="center" >
            
            {data.length===0 && <Grid item md={12} >
              <p style={{textAlign: 'center', margin: '0 0 20px 0'}}>אין אישורים להצגה</p>
            </Grid> }

            {data.length!==0 && data.map((item, index) => (
                <OpenPDFfiles data={item}
                
                />
            ))}



            {/* {(serviceStatus === 'InServiceFirstYear' || serviceStatus === 'InServiceSecondYear'  || serviceStatus === 'AfterService' ) &&
              <OpenPDFfiles texts={filesTexts.link101} setNoContent = {setNoContent} noContent = {noContent} />}

            { (serviceStatus === 'PreService') &&
              <OpenPDFfiles texts={filesTexts.link103} setNoContent = {setNoContent} noContent = {noContent} />}

            {(serviceStatus === 'InServiceFirstYear' || serviceStatus === 'InServiceSecondYear' ) &&
                <OpenPDFfiles texts={filesTexts.link104} setNoContent = {setNoContent} noContent = {noContent} /> } 

            {(serviceStatus === 'AfterService' ) &&
                <OpenPDFfiles texts={filesTexts.link102} setNoContent = {setNoContent} noContent = {noContent} /> }  */}

            {/* <OpenPDFfiles texts={filesTexts.linkLimudim} /> */}
            {/* <OpenPDFfiles texts={filesTexts.linkAvoda} />  */}
            
        </Grid>

        

    </div>
  )

}
/* 

פירוט קודים של מסמכים  הזמינים לרכזת "להורדה" דרך האפליקציה (קריאת שרת getDocument הסטטוס של המתנדבת נמשך מקריאת info)
 
1. ID 101  > טופס 101 (יוצג רק למתנדבים/ות בסטטוס SeviceStatus: "InServiceFirstYear" או InServiceSecondYear)

2. ID 102 >אישור תקופת שירות (יוצג רק למתנדבים/ות בסטטוס SeviceStatus: "AfterService וגם isEligibleForCertificate=True)

3. ID 103 > אישור הצבה (יוצג רק למתנדבים/ות בסטטוס SeviceStatus: "preService)
4. ID 104 > דוח תקבולים (דמי כיס) (יוצג רק לרק למתנדבים/ות בסטטוס SeviceStatus: "InServiceFirstYear" או InServiceSecondYear או AfterSrvice)
5. ID 118 > טופס בקשת אישור עבודה (יוצג רק למתנדבים בסטטוס SeviceStatus: "InServiceFirstYear" או InServiceSecondYear או SeviceStatus: "preService)
6. ID 119 > טופס בקשת אישור לימודים (יוצג רק למתנדבים בסטטוס SeviceStatus: "InServiceFirstYear" או InServiceSecondYear או SeviceStatus: "preService)
 */