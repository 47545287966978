import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0
import { map } from "lodash";
import {
  checkhasPerutDivuach,
  checkTextAreaReportContent,
  getClockApiMaakavIn,
  getClockApiMaakavOut,
  getClockApiMaakavStatus,
} from "./clockFunctions";

import loader from "../../img/preLoader.gif";
import note from "../../img/icons/clockPage/note.svg";
import icoReports from "../../img/icons/clockPage/icoReports.svg";

import ReactStopwatch from "react-stopwatch"; //npm i react-stopwatch    https://www.npmjs.com/package/react-stopwatch --   https://codesandbox.io/s/jolly-glade-e0yy8k?file=/src/App.js:23-68

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { toast } from "react-toastify";

import moment from "moment"; //npm install moment
import { getTypeRecordsList } from "./editClockFunctions";

export default function StartStopPage(props) {
  const { setSelectedPage } = props;

  const [loading, setLoading] = useState(false);

  const [reportTypeList, setReportsTypesList] = useState(false);

  const [selectedReportType, setSelectedReportType] = useState(false);
  const [textAreaReport, setTextAreaReport] = useState(false);
  const [percentWorked, setPercentWorked] = useState(false);

  //const [responseApiTemp, setResponseApiTemp] = useState(false);

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [MaakavStatus, setMaakavStatus] = useState(false);
  const [showSelectError, setShowSelectError] = useState(false);

  const [lastExit, setLastExit] = useState(false);

  let userJ = JSON.parse(localStorage.getItem("userData"));

  //reportTypesList
  useEffect(() => {
    if (!loading && !reportTypeList) {
      setReportsTypesList({
        data: {
          Items: getTypeRecordsList(),
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* setMaakavStatus */
  useEffect(() => {
    loadMaakavStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    console.log('MaakavStatus log', MaakavStatus);
  }, [MaakavStatus]);

  function loadMaakavStatus() {
    const sendObj = { IDNumber: userJ.IDNO, SessionKey: userJ.SessionKey };
    getClockApiMaakavStatus(
      "v2/volunteer/MaakavStatus",
      sendObj,
      setLoading,
      setMaakavStatus,
      setSelectedReportType,
      setTextAreaReport
    );
  }

  //After API set clock if IN
  useEffect(() => {
    if (MaakavStatus?.timeAllValues?.hours) {
      let now = new Date();

      let initialDateObject = new Date();
      initialDateObject.setHours(MaakavStatus.timeAllValues.hours);
      initialDateObject.setMinutes(MaakavStatus.timeAllValues.minutes);
      initialDateObject.setSeconds(0);

      const a = moment(initialDateObject);
      const b = moment(now);
      var duration = moment.duration(b.diff(a));

      if (
        !duration._data.hours &&
        !duration._data.minutes &&
        !duration._data.seconds
      ) {
        setSeconds(1);
      } else {
        setSeconds(duration._data.seconds);
      }

      setHours(parseInt(duration._data.hours));
      setMinutes(duration._data.minutes);

      const percentWorked = parseInt(
        (parseInt(duration._data.hours) * 100) / 8
      );

      if (percentWorked === 0) {
        setPercentWorked(5);
      } else {
        setPercentWorked(percentWorked);
      }
    }
  }, [MaakavStatus]);

  //refresh percentWorked
  setInterval(() => {
    //console.log('IN');

    if (MaakavStatus?.timeAllValues?.hours) {
      let now = new Date();

      let initialDateObject = new Date();
      initialDateObject.setHours(MaakavStatus.timeAllValues.hours);
      initialDateObject.setMinutes(MaakavStatus.timeAllValues.minutes);
      initialDateObject.setSeconds(0);

      const a = moment(initialDateObject);
      const b = moment(now);
      var duration = moment.duration(b.diff(a));
      const percentWorked = parseInt(
        (parseInt(duration._data.hours) * 100) / 8
      );

      if (percentWorked === 0) {
        setPercentWorked(5);
      } else {
        setPercentWorked(percentWorked);
      }
    }
  }, 60 * 1000 * 61); // 60 * 1000 milsec  // * 1 cada minuto  -> *61 cada hora

  //console.log('MaakavStatus >', MaakavStatus);
  //console.log('hours', hours);

  const startHandler = () => {

    if (!selectedReportType) {
      setShowSelectError(true);
      toast.error("יש לבחור סוג דיווח", {
        toastId: "selectedReportType",
      });

      //console.log('startStopHandler');
    } else if (
      checkhasPerutDivuach(selectedReportType, textAreaReport) &&
      !textAreaReport
    ) {
      setShowSelectError(true);
      toast.error("יש למלא פירוט דיווח", {
        toastId: "checkhasPerutDivuach",
      });
    } else if (
      !checkTextAreaReportContent(selectedReportType, textAreaReport)
    ) {
      //setShowSelectError(true);
      return false;
    } else {
      setShowSelectError(false);

      if (MaakavStatus?.api?.IsCurrIn) {
        toast.error("יש לעשות יציאה קודם", {
          toastId: "MaakavStatus",
        });
      } else {
        
        const sendObj = {
          IDNumber: userJ.IDNO,
          SessionKey: userJ.SessionKey,
          Typ: selectedReportType,
          MoreInfo: textAreaReport ? textAreaReport : '',
        };

        //console.log('Maakavin >', sendObj);


        getClockApiMaakavIn(
          "v2/volunteer/Maakavin",
          sendObj,
          setLoading,
          setMaakavStatus,
          setSelectedReportType,
          setTextAreaReport
        );

      }
    }
  };

  //console.log('maakavStatus', MaakavStatus);

  const [showPulse, setShowPulse] = useState(false);

  const stopHandler = () => {
    if (MaakavStatus?.api?.IsCurrIn) {
      const sendObj = { IDNumber: userJ.IDNO, SessionKey: userJ.SessionKey };

      getClockApiMaakavOut(
        "v2/volunteer/MaakavOut",
        sendObj,
        setLoading,
        setMaakavStatus,
        setSelectedReportType,
        setTextAreaReport,
        setPercentWorked,
        setLastExit
      );
    } else {
      setShowPulse("animate__animated animate__tada animate__repeat-2");
      setTimeout(() => {
        setShowPulse(false);
      }, 4000);

      toast.error("יש לעשות כניסה קודם או לפתוח דיווח ידני");
    }
  };

  useEffect(() => {
  
    //console.log('selectedReportType', selectedReportType);
    
    if(selectedReportType === '2') {

      setTextAreaReport('הכשרת שרות לאומי');

    } else if(selectedReportType === '3') {

      setTextAreaReport('נוכחות מחוץ למקום השרות');

    } else {

      setTextAreaReport('');
      
    }
    
  }, [selectedReportType])
  

  return (
    <div className="StartStopPage animate__animated animate__fadeIn">
      <header className="clear">
        <h1>נוכחות</h1>
        {/* <p>תודה שבחרת להתנדב איתנו!</p> */}
      </header>

      <img
        src={loader}
        alt="loader"
        className={
          !loading
            ? "loader"
            : "loader active animate__animated animate__fadeIn"
        }
      />

      {/* reportType SELECT */}
      {reportTypeList?.data?.Items && (
        <div className="selectCont">
          <img src={note} alt="note" />

          <Form.Control
            as="select"
            className={"chooseReportSelect"}
            isInvalid={showSelectError && !selectedReportType}
            disabled={MaakavStatus?.api?.IsCurrIn}
            /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
            //type={ config.typeInput }
            //name='reportType'
            onChange={(e) => setSelectedReportType(e.target.value)}
            value={selectedReportType}
            //onFocus={this.populateSelect}
          >
            <option value="">סוג דיווח</option>

            {map(reportTypeList.data.Items, (item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              );
            })}
          </Form.Control>
        </div>
      )}

      {checkhasPerutDivuach(selectedReportType, textAreaReport) && (
        <div className="textAreaCont">
          <Form.Control
            as="textarea"
            className={"textAreaReport"}
            disabled={MaakavStatus?.api?.IsCurrIn}
            isInvalid={
              showSelectError &&
              checkhasPerutDivuach(selectedReportType, textAreaReport) &&
              !textAreaReport
            }
            /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
            //type={ config.typeInput }
            //name='reportType'
            onChange={(e) => setTextAreaReport(e.target.value)}
            value={
              textAreaReport && textAreaReport !== "False" ? textAreaReport : ""
            }
            placeholder="פירוט דיווח"
            //onFocus={this.populateSelect}
          />
        </div>
      )}

      <div
        className={`buttonsDown ${
          MaakavStatus?.api?.IsCurrIn ? "isIn" : "isOut"
        }`}
      >
        <button
          className={`start ${
            selectedReportType
              ? "animate__animated animate__bounceIn"
              : "disabled"
          }`}
          onClick={startHandler}
        >
          <span>כניסה</span>
        </button>
        <button className="stop" onClick={stopHandler}>
          <span>יציאה</span>
        </button>
      </div>

      <button
        className={`openReportPage ${showPulse ? showPulse : ""}`}
        onClick={() => setSelectedPage("reportsPage")}
      >
        <img src={icoReports} alt="icoReports" />
        <span>דיווחי נוכחות</span>
      </button>

      {/* START CLOCK:  */}
      {MaakavStatus?.api?.IsCurrIn ? (
        <div className={`playStopBtn ${!selectedReportType && "disabled"} `}>
          <div className="buttonCont stopBtn">
            {(minutes || hours || seconds) && (
              <ReactStopwatch
                seconds={seconds}
                minutes={minutes}
                hours={hours}
                render={({ formatted, hours, minutes, seconds }) => {
                  return (
                    <div className="clock">
                      <div className="clockNumbers">{formatted}</div>
                      <p>
                        {MaakavStatus?.weekday}, {MaakavStatus?.day}
                      </p>
                      <div className="showInTime">
                        <h3>התקבלה כניסה בשעה</h3>
                        <span>{MaakavStatus?.time}</span>
                      </div>
                    </div>
                  );
                }}
              />
            )}

            <CircularProgressbar
              value={percentWorked}
              text=""
              strokeWidth={4}
              styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                //rotation: 0.25,

                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                //strokeLinecap: 'butt',

                // Text size
                //textSize: '16px',

                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.5,

                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',

                // Colors
                pathColor: `rgba(27, 213, 145, ${100 / 100})`, //percentWorked
                textColor: "#f88",
                trailColor: "white",
                backgroundColor: "#3e98c7",
              })}
            />
          </div>
        </div>
      ) : (
        /* STOP CLOCK: */
        <div
          className={`playStopBtn ${
            !selectedReportType && !lastExit ? "disabled" : ""
          } `}
        >
          <div className="buttonCont stopBtn">
            <div className="clock">
              <div className="clockNumbers">00:00:00</div>
              <p>
                {new Date().toLocaleDateString("he", { weekday: "long" })}{" "}
                {new Date().toLocaleDateString("he", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                })}
              </p>
              {lastExit && (
                <div className="lastExit">
                  <p>יציאה אחרונה ב-</p>
                  <strong>{lastExit}</strong>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}