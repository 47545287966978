import React, { useMemo } from "react";
import { RestUrls } from "../../Components/-Helpers-/config";
import Lottie from "react-lottie-player";
import lottieJson from "./lottie.json";

const SortiesIndex = ({ siteInfo }) => {
  const topPic = useMemo(() => `${RestUrls.pagesPictures}/search/searchTop.jpg?v=1`, []);
  const m_topPic = useMemo(() => `${RestUrls.pagesPictures}/register/m_registerTop.jpg?v=2`, []);
  const isMobile = siteInfo.isMobile;

  // Use localStorage to cache the Lottie animation data
  const cachedLottieData = useMemo(() => {
    const cachedData = localStorage.getItem('sortiesLottieData');
    if (cachedData) {
      return JSON.parse(cachedData);
    }
    localStorage.setItem('sortiesLottieData', JSON.stringify(lottieJson));
    return lottieJson;
  }, []);

  return (
    <div className="DataSection trainingPageIndex">
      <img
        className="topPic full_width"
        src={isMobile ? m_topPic : topPic}
        alt="top"
        loading="lazy"
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          padding: "3rem 0",
        }}
      >
        <h1>מיונים</h1>
        <h2>מסך זה נמצא בבניה</h2>

        <Lottie
          style={{ width: "100%", maxWidth: "351px", height: "auto" }}
          loop
          animationData={cachedLottieData}
          play
        />
      </div>
    </div>
  );
};

export default React.memo(SortiesIndex);
