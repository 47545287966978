import React, { useState, useEffect, useCallback } from "react";
import { getAllUrlParams } from "../../Components/-Helpers-/UrlParameters";
import { RestUrls } from "../../Components/-Helpers-/config";

import SearchUpPlaces from "./includes/SearchUpPlaces";
import SearchResultsPlaces from "./includes/SearchResultsPlaces";
import InfiniteScroll from "react-infinite-scroller";
import loader from "../../img/preLoader.gif";

import Fab from "@mui/material/Fab";
import SearchIcon from "@mui/icons-material/Search";

import { animateScroll as scroll } from "react-scroll";
import axios from "axios";

import FavoriteButton from "./includes/FavoriteButton";
import ShowInfoPopUp from "./ShowInfoPopUp";

const SherutPlacesIndex = ({ siteInfo }) => {
  const urlParams = getAllUrlParams(window.location.search);
  const urlQuery = siteInfo.search ? getAllUrlParams(siteInfo.search) : false;

  const [state, setState] = useState({
    loading: false,
    freeSearch: urlParams?.freesearch ? decodeURI(urlParams.freesearch) : "",
    Maslol: urlParams?.maslol ? decodeURI(urlParams.maslol) : "",
    YEAR: urlParams?.year ? decodeURI(urlParams.year) : 'תשפ"ד',
    Thum: urlParams?.thum && urlParams?.thum !== "null"
      ? decodeURI(urlParams.thum).split(",")
      : [],
    rakaz: "",
    City: urlParams?.city || "",
    ZoneName: urlParams?.zonename || "",
    orderBy: "",
    searchFilters: false,
    items: [],
    hasMoreItems: true,
    nextHref: 0,
    sayarIdQuery: urlQuery && urlQuery.id ? urlQuery.id : "",
    pageName: "sayarot",
    allmekSherut: "0",
    favoritesPlaces: localStorage.getItem("favoritesPlaces")
      ? localStorage.getItem("favoritesPlaces").split(",")
      : [],
    showfavoritesPlaces: false,
    openCloseSearchMobile: false,
  });

  const updateStateFromSearch = useCallback((searchState) => {
    console.log("searchState", searchState);
    setState(prevState => ({
      ...prevState,
      ...searchState,
      items: [],
      hasMoreItems: true,
      nextHref: 0,
    }));
  }, []);

  const handleChangeSelect = useCallback((event) => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value,
      items: [],
      hasMoreItems: true,
      nextHref: 0,
    }));
  }, []);

  const showFavoritesOnly = useCallback((param) => {
    setState(prevState => ({
      ...prevState,
      showfavoritesPlaces: param,
      items: [],
      hasMoreItems: true,
      nextHref: 0,
    }));
  }, []);

  const loadItems = useCallback(() => {
    const API = `${RestUrls.baseApiUrl}/MekSherut/getMekSherut`;
    const sendObj = {
      auth: "mekSherut",
      token: RestUrls.Code,
      page: state.nextHref,
      freeSearch: state.freeSearch,
      year: state.YEAR,
      City_Value: state.City,
      City_Zone: state.ZoneName,
      Thum_Key: state.Thum,
      Maslol: state.Maslol,
      sayarIdQuery: state.sayarIdQuery,
      favoritesPlaces: state.favoritesPlaces.join(","),
      showfavoritesPlaces: state.showfavoritesPlaces,
      openCloseSearchMobile: false,
      orderBy: state.orderBy,
    };

    axios.post(API, JSON.stringify(sendObj), {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((res) => {
      const { data } = res;
      console.log("data you looking for", data);
      if (data && data.items[0] && data.items[0].id) {
        const uniqueIds = new Set();
        const newItems = [...state.items];
        data.items.forEach((item) => {
          if (!uniqueIds.has(item.id)) {
            uniqueIds.add(item.id);
            newItems.push(item);
          }
        });
        setState(prevState => ({
          ...prevState,
          allmekSherut: data.allmekSherut,
          items: newItems,
          hasMoreItems: true,
          nextHref: prevState.nextHref + 1,
        }));
      } else {
        setState(prevState => ({ ...prevState, hasMoreItems: false }));
      }
    })
    .catch((err) => {
      console.error(err);
      alert("שגיאה");
    });
  }, [state]);

  const closeSearchMobile = useCallback(() => {
    setState(prevState => ({ ...prevState, openCloseSearchMobile: false }));
    scroll.scrollTo(600);
  }, []);

  const topPic = RestUrls.pagesPictures + "/sherutPlaces/topSherut.jpg?v=3";
  const m_topPic = RestUrls.pagesPictures + "/sherutPlaces/topSherut.jpg?v=3";
  const isMobile = siteInfo.isMobile || false;
  const showFavoritesBtn = state.favoritesPlaces.length > 0;

  return (
    <div className="SherutPlacesIndex">
      <img
        className="topPic full_width"
        src={isMobile ? m_topPic : topPic}
        alt="top"
      />

      <div className="btnsUp">
        <a href="/userConsole" target="_blank" rel="noopener noreferrer">
          <span>סיירות האגודה להתנדבות &raquo;</span>
        </a>
      </div>

      <h1>מקומות השרות באגודה להתנדבות - לבחירתך!</h1>

      <div className="placesContainer">
        <div className="SearchCategoty">
          <FavoriteButton
            allResults={state.allmekSherut}
            is_disabled={!state.sayarIdQuery && showFavoritesBtn}
            is_all={state.showfavoritesPlaces}
            showFavoritesOnly={showFavoritesOnly}
          />
        </div>

        <ShowInfoPopUp />

        {!state.sayarIdQuery && !state.showfavoritesPlaces && (
          <div className="SearchUp">
            <SearchUpPlaces
              mobile={isMobile}
              closeSearch={closeSearchMobile}
              currentState={state}
              updateSearch={updateStateFromSearch}
            />
          </div>
        )}

        {isMobile && !state.sayarIdQuery && (
          <Fab
            className="mobileSearchBtnFloat searchMobileCont animate__animated animate__bounceIn"
            color="primary"
            aria-label="חיפוש"
            onClick={() => setState(prevState => ({ ...prevState, openCloseSearchMobile: !prevState.openCloseSearchMobile }))}
          >
            <SearchIcon />
          </Fab>
        )}

        {isMobile && !state.sayarIdQuery && state.openCloseSearchMobile && (
          <div className="searchMobileOpenClose animate__animated animate__fadeInDown animate__faster">
            <button
              className="openCloseSearchBtn xBtn"
              onClick={() => setState(prevState => ({ ...prevState, openCloseSearchMobile: false }))}
            >
              <span className="closeMe">X</span>
            </button>

            <div className="searchMobileCont">
              <SearchUpPlaces
                mobile={isMobile}
                closeSearch={closeSearchMobile}
                updateSearch={updateStateFromSearch}
              />
            </div>
          </div>
        )}

        {state.sayarIdQuery && (
          <div className="shareHeader">
            <a className="bounce" href="/sherutPlaces">
              &laquo; חזרה למקומות השירות
            </a>
          </div>
        )}

        <InfiniteScroll
          pageStart={0}
          loadMore={loadItems}
          hasMore={state.hasMoreItems}
          loader={<img src={loader} key={1231} alt="loader" className="loaderInfiniteScroll animate__animated animate__fadeIn" />}
        >
          <div className="SearchResults" name="toSearchResults">
            {state.items && state.items.length > 0 ? (
              <SearchResultsPlaces
                showOpen={state.sayarIdQuery}
                items={state.items}
                favoritesPlaces={state.favoritesPlaces}
                chageFavorites={(chageFavorites) =>
                  setState(prevState => ({ ...prevState, favoritesPlaces: chageFavorites }))
                }
              />
            ) : (
              <h2 className="noResults">אין תוצאות...</h2>
            )}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default SherutPlacesIndex;
