import { RestUrls } from "../../Components/-Helpers-/config";
import { toast } from "react-toastify";
import axios from "axios";
//import moment from "moment";   //npm install moment
//import validator from "validator";
import { map } from "lodash";

export function getTraning(url, sendObj, setLoading, setResponseData) {
  setLoading(true);

  const json = JSON.stringify(sendObj);
  console.log("url", url);
  console.log("this is the url : ", RestUrls.sherutLeumiApi + url);
  console.log("this is the json : ", json);

  // axios.post( RestUrls.sherutLeumiApi + url, json, {
  axios
    .post("https://vu-apiws.azurewebsites.net/api/" + url, json, {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
      setLoading(false);
      console.log("response", res);

      if (!res?.data?.Result || res?.data?.Result !== "Success") {
        setResponseData(false);
        return toast.error("שגיאה בקריאת נתונים");
      }

      if (!res?.data?.Dates.length) {
        toast.warning("אין מידע על הכשרות");
        return false;
      } else {
        arrangeMyTranings(res?.data?.Dates, setResponseData);
      }
    })

    .catch((error) => {
      setLoading(false);
      setResponseData({});
      toast.error("שגיאה", {
        toastId: "error",
      });
      console.log(error);
    });
  /* 
        .finally(() => {
            setLoading(false);
        }) */
}

function arrangeMyTranings(Dates, setResponseData) {
  console.log(Dates);

  let AllDates = [];

  map(Dates, (item) => {
    AllDates.push({
      id: item?.TrainingID,
      TrainingID: item?.TrainingID,
      rsvpRequired: item?.rsvpRequired,
      StartDate: item?.StartDate,
      EndDate: item?.EndDate,
      Title: item?.Title,
      Days: item?.Days,
    });
  });

  setResponseData(AllDates);
}

export function iWillBeThere(url, sendObj, setLoading, reloadItems) {
  setLoading(true);

  const json = JSON.stringify(sendObj);

  axios
    .post("https://vu-apiws.azurewebsites.net/api/" + url, json, {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
      console.log("response u will be there: ", res);
      setLoading(false);
      console.log(res?.data);

      if (!res?.data?.Result || res?.data?.Result !== "Success") {
        //setResponseData(false);
        return toast.error("שגיאה בקריאת נתונים");
      }

      toast.success("עודכן בהצלחה");
      reloadItems();

      /* const sendObj = {
                idno: userJ.IDNO,
                SessionKey: userJ.SessionKey,
                TrainingID: item?.TrainingID,
                RSVP: valueChange
    
            } */
    })

    .catch((error) => {
      setLoading(false);
      //setResponseData({});
      toast.error("שגיאה", {
        toastId: "error",
      });
      console.log(error);
    });
  /* 
        .finally(() => {
            setLoading(false);
        }) */
}

export function entryTraining(
  url,
  sendObj,
  setLoading,
  setIsArrived,
  reloadItems
) {
  setLoading(true);

  const json = JSON.stringify(sendObj);

  axios
    .post("https://vu-apiws.azurewebsites.net/api/" + url, json, {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
      setLoading(false);
      console.log(res?.data);

      if (!res?.data?.Result || res?.data?.Result !== "Success") {
        //setResponseData(false);
        return toast.error("שגיאה בקריאת נתונים");
      }

      setIsArrived(true);
      reloadItems();
    })

    .catch((error) => {
      setLoading(false);
      //setResponseData({});
      toast.error("שגיאה", {
        toastId: "error",
      });
      console.log(error);
    });
  /* 
        .finally(() => {
            setLoading(false);
        }) */
}

export function dateFromStringObj(yearMonthDay) {
  if (yearMonthDay) {
    const date = {
      day: yearMonthDay.substring(6, 8),
      month: yearMonthDay.substring(4, 6),
      year: yearMonthDay.substring(0, 4),
    };

    return date;
  } else {
    return false;
  }
}
