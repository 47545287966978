import React, { Component, Fragment } from "react";
import { RestUrls } from "../../Components/-Helpers-/config";
import axios from "axios";
import SearchUp from "./search/SearchUp";
import SearchResults from "./search/SearchResults";
import SearchCategory from "./search/SearchCategory";
import MySayarot from "./search/MySayarot";
import InfiniteScroll from "react-infinite-scroller";
import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */
import goUp from "../../img/sherut-leumi/svg/goUp.svg";
import loader from "../../img/preLoader.gif";
import vPic from "../../img/sherut-leumi/svg/sherutPlaces/v.svg";
import { Form } from "react-bootstrap";
import { getAllUrlParams } from "./../../Components/-Helpers-/UrlParameters";
import TextDayShowSayarot from "./texts/TextDayShowSayarot";

export default class UserConsoleSearchIndex extends Component {
  constructor(props) {
    super(props);
    const urlQuery = getAllUrlParams(window.location.href);

    let rakazId = urlQuery.rakazid ? urlQuery.rakazid : false;
    let sayeretId = urlQuery.sayeretid ? urlQuery.sayeretid : false;

    if (localStorage.getItem("rakazid") && !rakazId) {
      rakazId = localStorage.getItem("rakazid");
    } else if (localStorage.getItem("sayeretid") && !sayeretId) {
      sayeretId = localStorage.getItem("sayeretid");
    }

    localStorage.setItem("rakazid", "");
    localStorage.setItem("sayeretid", "");

    this.state = {
      loading: false,
      freeSearch: "",
      rakaz: rakazId ? rakazId : "",
      sayeretId: sayeretId ? sayeretId : "",
      city: "",
      orderBy: "",
      searchFilters: false,
      allItems: [], // שמירת כל התוצאות
      items: [], // התוצאות שמוצגות כרגע על המסך
      hasMoreItems: true,
      nextHref: 0,
      pageName: "sayarot",
      showGreenOk: false,
      itemsPerPage: 20, // מספר התוצאות שמוצגות בכל גלילה
    };
  }

  updateStateFromSearch = (searchState) => {
    this.setState({
      items: [],
      hasMoreItems: true,
      nextHref: 0,
    });

    this.setState(searchState, () => {
      this.loadItems(); // קריאה ראשונית לטעינת הפריטים
    });
  };

  handleChangeSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.setState({
        items: [],
        hasMoreItems: true,
        nextHref: 0,
      });
    });
  };

  loadItems = () => {
    const { allItems, items, nextHref, itemsPerPage } = this.state;

    // אם אין עוד פריטים לטעון
    if (nextHref * itemsPerPage >= allItems.length) {
      this.setState({ hasMoreItems: false });
      return;
    }

    // חיתוך התוצאות בהתאם לדף
    const newItems = allItems.slice(nextHref * itemsPerPage, (nextHref + 1) * itemsPerPage);

    this.setState({
      items: [...items, ...newItems],
      nextHref: nextHref + 1,
    });
  };

  componentDidMount() {
    this.loadInitialData();
  }

  loadInitialData = () => {
    let userJ = JSON.parse(localStorage.getItem("userData"));
    let sessionKey = userJ ? userJ.SessionKey : null;

    const API = "https://vu-apiws.azurewebsites.net/api/v2/Data/sayarot";
    let sendObj = {
      auth: "sayarot",
      token: RestUrls.Code,
      page: 0,
      freeSearch: this.state.freeSearch,
      sayeretId: this.state.sayeretId,
      rakaz: this.state.rakaz,
      city: this.state.city,
      orderBy: this.state.orderBy,
      SessionKey: sessionKey,
    };

    axios
      .post(API, JSON.stringify(sendObj), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        const data = res?.data;

        if (data.Sayarot?.length > 0) {
          this.setState({
            allItems: data.Sayarot, // שמירת כל התוצאות ב-allItems
            items: data.Sayarot.slice(0, this.state.itemsPerPage), // הצגת הדף הראשון
            nextHref: 1, // עדכון מספר הדף הבא
            hasMoreItems: data.Sayarot.length > this.state.itemsPerPage, // בדיקה אם יש עוד תוצאות להציג
          });
        } else {
          this.setState({ hasMoreItems: false });
        }
      })
      .catch((err) => {
        console.error(err);
        alert("שגיאה בהבאת הנתונים");
      });
  };

  changeMenuRightAndShowSign = (id) => {
    this.props.changeMenuright(id);

    if (!id.isActive) {
      this.setState(
        {
          showGreenOk: id.name,
        },
        () => {
          setTimeout(() => {
            this.setState({ showGreenOk: false });
          }, 4000);
        }
      );
    }
  };

  render() {
    const host = window.location.host;
    const isMobile = this.props.siteInfo.isMobile || false;
    const loading = (
      <img
        src={loader}
        key={1231}
        alt="loader"
        className={"loaderInfiniteScroll animate__animated animate__fadeIn"}
      />
    );

    let topPic = RestUrls.pagesPictures + "/search/searchTop.jpg?v=2";
    const m_topPic = RestUrls.pagesPictures + "/register/m_registerTop.jpg?v=5";

    return (
      <>
        {true && (
          <div className="SearchSection">
            {this.state.showGreenOk ? (
              <div className="messaggePopUp animate__animated animate__fadeInDown">
                <div className="messaggeCont">
                  <img src={vPic} alt="תודה שנרשמת לסיירת" />
                  <span>תודה שנרשמת לסיירת {this.state.showGreenOk}</span>
                </div>
              </div>
            ) : null}

            <img
              className="goUpPic animate__animated animate__bounceIn"
              src={goUp}
              alt="לעלות"
              onClick={() => scroll.scrollToTop({ duration: 0 })}
            />

            {isMobile ? (
              <a
                href="https://sherut-leumi.co.il/"
                target="_self"
                rel="noopener noreferrer"
              >
                <img className="topPic full_width" src={m_topPic} alt="top" />
              </a>
            ) : (
              <img className="topPic full_width" src={topPic} alt="top" />
            )}

            <div className="SearchContainer">
              <h1>הסיירות של האגודה להתנדבות – בחרי את הסיירת שלך</h1>

              <div className="SearchCategoty">
                <SearchCategory
                  currentPage={this.state.pageName}
                  changePage={(name) =>
                    this.setState({
                      pageName: name,
                      freeSearch: "",
                      rakaz: "",
                      city: "",
                      items: [],
                      hasMoreItems: true,
                      nextHref: 0,
                    })
                  }
                />
              </div>

              {this.state.pageName === "mySayarot" ? (
                <div className="pageMySayarot">
                  <MySayarot
                    changeMenuright={(id) => this.props.changeMenuright(id)}
                  />
                </div>
              ) : (
                <div className="pageSayarot">
                  <div className="SearchUp">
                    <SearchUp
                      currentState={this.state}
                      updateSearch={(searchState) =>
                        this.updateStateFromSearch(searchState)
                      }
                    />
                  </div>

                  <div className="bannerCall">
                    <Fragment>
                      <TextDayShowSayarot isMobile={isMobile} step={"step2"} />
                    </Fragment>
                  </div>

                  <div className="orderBy">
                    <Form.Control
                      as="select"
                      className={"customSelectInput "}
                      name="orderBy"
                      onChange={this.handleChangeSelect}
                      value={this.state.orderBy}
                    >
                      <option value="">מיון לפי</option>
                      <option value="מקומות שנותרו">מקומות שנותרו</option>
                      <option value="תאריך">תאריך</option>
                      <option value="מיקום">מיקום</option>
                    </Form.Control>
                  </div>

                  <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadItems}
                    hasMore={this.state.hasMoreItems}
                    loader={loading}
                  >
                    <div className="SearchResults">
                      {this.state.items.length > 0 ? (
                        <SearchResults
                          activeSayeretId={this.state.sayeretId}
                          changeMenuright={(id) =>
                            this.changeMenuRightAndShowSign(id)
                          }
                          sayarotData={this.state.items}
                        />
                      ) : (
                        <h2 className="noResults">אין תוצאות...</h2>
                      )}
                    </div>
                  </InfiniteScroll>
                </div>
              )}
            </div>
          </div>
        )}
        {/* {process.env.REACT_APP_SEASON_TOGGLE && <>נחזור בקרוב</>} */}
      </>
    );
  }
}
