export function getAllUrlParams(url) {
  // get query string from url (optional) or window
  const queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  const params = new URLSearchParams(queryString);
  const obj = {};

  // iterate over all parameters
  for (const [key, value] of params.entries()) {
    const paramName = key.toLowerCase();
    const paramValue = value ? value.toLowerCase() : true;

    // if the paramName ends with square brackets, e.g. colors[] or colors[2]
    if (paramName.match(/\[(\d+)?\]$/)) {
      const baseKey = paramName.replace(/\[(\d+)?\]/, '');
      if (!obj[baseKey]) obj[baseKey] = [];

      // if it's an indexed array e.g. colors[2]
      if (paramName.match(/\[\d+\]$/)) {
        const index = /\[(\d+)\]/.exec(paramName)[1];
        obj[baseKey][index] = paramValue;
      } else {
        obj[baseKey].push(paramValue);
      }
    } else {
      if (!obj[paramName]) {
        obj[paramName] = paramValue;
      } else if (Array.isArray(obj[paramName])) {
        obj[paramName].push(paramValue);
      } else {
        obj[paramName] = [obj[paramName], paramValue];
      }
    }
  }

  return obj;
}
