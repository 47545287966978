import { RestUrls } from "../config";

/**
 * Fetches data from the API.
 * 
 * @param {string} url - The endpoint URL.
 * @param {Object} objectToSend - The object to send in the request body.
 * @param {string} controller - The controller name.
 * @param {string} [auth="all"] - The authorization type.
 * @param {string} [baseUrl=RestUrls.baseApiUrl] - The base URL for the API.
 * @returns {Object} The response data from the API.
 */
export default async function getDataFromApi(
  url,
  objectToSend,
  controller,
  auth = "all",
  baseUrl = RestUrls.baseApiUrl
) {
  // Construct the full API URL
  const API = `https://vu-apiws.azurewebsites.net/api/v2/${controller}/${url}`;

  console.log("API::", API);
  console.log("object to send", objectToSend);

  // Set a flag to indicate development mode
  objectToSend.isRotemDev = true;

  // Create an object containing authentication details
  const userPass = {
    auth: auth,
    token: RestUrls.Code,
  };

  // Merge the authentication details with the object to send
  const items = { ...userPass, ...objectToSend };
  console.log("items", JSON.stringify(items));

  // Log additional information if in development mode
  if (objectToSend.isRotemDev) {
    console.log("isRotemDev >", items.isRotemDev);
    console.log("controller >", controller);
    console.log("url >", url);
  }

  try {
    // Send a POST request to the API
    const res = await fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(items),
    });

    // Parse the response data
    const data = await res.json();
    if (objectToSend.isRotemDev) {
      console.log("isRotemDev Response: ", data);
    }

    // Handle unauthorized response
    if (data === "unauthorized" || res.status === 403) {
      localStorage.setItem("userData", "");
      window.open("/login", "_self");
    } else if (data) {
      return { headerStatus: res.status, ...data };
    }
  } catch (e) {
    console.error("Error:", e);
    alert("שגיאה", e);
  }
}
