import React, { useState, useEffect } from "react";
import axios from "axios";
import { RestUrls } from "../../../Components/-Helpers-/config";

const AvatarPratimMobilePic = () => {
  const [avatarPicture, setAvatarPicture] = useState(null);

  useEffect(() => {
    const fetchAvatarImage = async () => {
      const user = JSON.parse(localStorage.getItem("userData"));
      await getAvatarImage({ idno: user.IDNO, token: RestUrls.Code }, setAvatarPicture);
    };
    fetchAvatarImage();
  }, []);

  if (!avatarPicture?.base64Pic) {
    return null;
  }

  return (
    <div className="mobileAvatarPicture">
      <img src={avatarPicture.base64Pic} alt="תמונת אווטאר" />
    </div>
  );
};

const getAvatarImage = async (sendObj, setAvatarPicture) => {
  try {
    const response = await axios.post(
      `${RestUrls.baseApiUrl}users/getAvatarImage`,
      sendObj,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    setAvatarPicture(response.data);
  } catch (error) {
    console.error("Error fetching avatar image:", error);
  }
};

export default AvatarPratimMobilePic;
