import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Form, FormControl, Button } from "react-bootstrap";
import { getAllUrlParams } from "../../../Components/-Helpers-/UrlParameters";
import getDataFromApi from "../../../Components/-Helpers-/api/getDataFromApi";
import MultiSelectReact from "./MultiSelectReact";

const SearchUpPlaces = React.memo(({ currentState, updateSearch, mobile, closeSearch }) => {
  const urlParams = useMemo(() => getAllUrlParams(window.location.search), []);
  const [state, setState] = useState(() => ({
    urlParams,
    searchDataResp: false,
    loading: false,
    yearChanged: false,
    freeSearch: currentState?.freeSearch || urlParams?.freesearch ? decodeURI(urlParams.freesearch) : "",
    Maslol: currentState?.Maslol || urlParams?.maslol ? decodeURI(urlParams.maslol) : "",
    Thum: currentState?.Thum || "",
    YEAR: currentState?.YEAR || urlParams?.year || "",
    ZoneName: currentState?.ZoneName || urlParams?.zonename ? decodeURI(urlParams.zonename) : "",
    City: currentState?.City || urlParams?.city ? decodeURI(urlParams.city) : "",
    clearMulti: false,
    updateFilters: false,
  }));

  useEffect(() => {
    if (!state.searchDataResp) {
      sendtoAdmin("getMekSherutSearchParams", "mekSherut", {}, "searchDataResp", "mekSherut");
    }
  }, [state.searchDataResp]);

  const sendtoAdmin = useCallback((url, controller, objectToSend, setStateName = "data", auth = "all") => {
    setState(prev => ({ ...prev, loading: true }));

    getDataFromApi(url, objectToSend, controller, auth).then(getData => {
      setState(prev => ({
        ...prev,
        [setStateName]: getData,
        loading: false,
        YEAR: getData?.currentYear?.substring(1) || prev.YEAR
      }));
    });
  }, []);

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    setState(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleChangeThum = useCallback((params) => {
    setState(prev => ({ ...prev, Thum: params }));
  }, []);

  const sendForm = useCallback(() => {
    setState(prev => ({ ...prev, updateFilters: Date.now() }));
    updateSearch(state);
    if (mobile) {
      closeSearch();
    }
  }, [state, updateSearch, mobile, closeSearch]);

  const handleChangeSelect = useCallback((event) => {
    const { name, value } = event.target;
    setState(prev => ({
      ...prev,
      [name]: value,
      ...(name === "ZoneName" && value === "" ? { City: "" } : {})
    }));
  }, []);

  const searchFilters = useCallback((param, isMalol) => {
    if (isMalol) {
      setState(prev => ({
        ...prev,
        freeSearch: "",
        Maslol: "",
        Thum: "",
        YEAR: 'תשפ"ג',
        ZoneName: "",
        City: "",
      }));
    } else {
      setState(prev => ({ ...prev, [param]: "" }), sendForm);
    }
  }, [sendForm]);

  const clearFields = useCallback(() => {
    setState(prev => ({
      ...prev,
      freeSearch: "",
      Maslol: "",
      Thum: [],
      YEAR: 'תשפ"ו',
      ZoneName: "",
      City: "",
      clearMulti: Date.now(),
    }), sendForm);
  }, [sendForm]);

  const flagSend = !!state.Maslol;

  const renderFormGroups = useMemo(() => {
    if (!state.searchDataResp) return null;

    const commonProps = {
      handleChangeSelect,
      state,
      handleChange,
      handleChangeThum,
      searchDataResp: state.searchDataResp,
      clearMulti: state.clearMulti,
    };

    return mobile ? (
      <MobileFormGroups {...commonProps} />
    ) : (
      <DesktopFormGroups {...commonProps} setState={setState} />
    );
  }, [state, mobile, handleChangeSelect, handleChange, handleChangeThum, setState]);

  return (
    <div className="SearchAndResults">
      <div
        style={{
          backgroundImage: `url("https://sherut-leumi.co.il/wp-content/uploads/2023/05/Group-162537.png")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="SearchUpCont GreyPanel"
      >
        {renderFormGroups}
        {flagSend && (
          <>
            <Button
              disabled={!flagSend}
              className="sendBlueBtn withDisabled"
              onClick={sendForm}
              size="lg"
              variant={flagSend ? "success animate__animated animate__bounceIn" : "primary"}
            >
              מצא לי מקום שירות
            </Button>
            <button className="btnClear" onClick={clearFields}>
              ניקוי שדות
            </button>
          </>
        )}
      </div>
    </div>
  );
});

const MobileFormGroups = React.memo(({ handleChangeSelect, state, handleChange, handleChangeThum, searchDataResp, clearMulti }) => (
  <div>
    <div className="line">
      <Form.Group className="inputsWithLabel selectIco maslulIco iconAfter">
        <Form.Label>מסלול</Form.Label>
        <FormControl
          as="select"
          className="inputsWithLabel selectIcoBG"
          onChange={handleChangeSelect}
          name="Maslol"
          value={state.Maslol}
        >
          <option value="">בחרו מסלול</option>
          <option disabled>----</option>
          {searchDataResp.allMaslul.map((item) => (
            <option value={item.Maslol} key={item.Maslol}>
              {item.Maslol}
            </option>
          ))}
        </FormControl>
      </Form.Group>
    </div>

    <div className="line inputs2">
      <Form.Group className="inputsWithLabel shanaIco iconAfter">
        <Form.Label>שנה</Form.Label>
        <FormControl
          as="select"
          className="inputsWithLabel selectIcoBG"
          onChange={handleChangeSelect}
          name="YEAR"
          disabled={!state.Maslol}
          value={state.YEAR}
        >
          <option value="">בחרו שנה</option>
          <option disabled>----</option>
          <option value='תשפ"ה'>תשפ"ה</option>
          <option value='תשפ"ו'>תשפ"ו</option>
        </FormControl>
      </Form.Group>

      <Form.Group className="inputsWithLabel">
        <Form.Label>תחום</Form.Label>
        <MultiSelectReact
          disabled={!state.Maslol}
          clearMulti={clearMulti}
          options={searchDataResp.allThum}
          handleChangeThum={handleChangeThum}
        />
      </Form.Group>
    </div>

    <div className="line inputs2">
      <Form.Group className="inputsWithLabel ezorIco iconAfter">
        <Form.Label>איזור</Form.Label>
        <FormControl
          as="select"
          className="inputsWithLabel selectIcoBG"
          onChange={handleChangeSelect}
          name="ZoneName"
          value={state.ZoneName}
          disabled={!state.Maslol}
        >
          <option value="">בחרו</option>
          <option disabled>----</option>
          {searchDataResp.allCity_Zone.map((item) => (
            <option value={item.City_Zone} key={item.City_Zone}>
              {item.City_ZoneName}
            </option>
          ))}
        </FormControl>
      </Form.Group>

      <Form.Group className="inputsWithLabel placeIco iconAfter">
        <Form.Label>ישוב</Form.Label>
        <FormControl
          as="select"
          className="inputsWithLabel selectIcoBG"
          onChange={handleChangeSelect}
          name="City"
          value={state.City}
          disabled={!(state.Maslol && state.ZoneName)}
        >
          <option value="">בחרו</option>
          <option disabled>----</option>
          {searchDataResp.cities.map((item) => (
            <option value={item.City_Value} key={item.City_Value}>
              {item.City_Key}
            </option>
          ))}
        </FormControl>
      </Form.Group>
    </div>

    <div className="line">
      <Form.Group className="inputsWithLabel searchIco">
        <Form.Label>חיפוש חופשי</Form.Label>
        <FormControl
          disabled={!state.Maslol}
          placeholder="לדוגמא: חינוך מיוחד"
          className="inputsWithLabel"
          onChange={handleChange}
          name="freeSearch"
          value={state.freeSearch}
          type="text"
        />
      </Form.Group>
    </div>
  </div>
));

const DesktopFormGroups = React.memo(({ handleChangeSelect, state, handleChange, handleChangeThum, searchDataResp, clearMulti, setState }) => (
  <div>
    <div className="line inputs3">
      <Form.Group className="inputsWithLabel selectIco maslulIco iconAfter">
        <Form.Label style={{ color: "white" }}>מסלול</Form.Label>
        <FormControl
          as="select"
          className="inputsWithLabel selectIcoBG"
          onChange={handleChangeSelect}
          name="Maslol"
          value={state.Maslol}
        >
          <option value="">בחרו מסלול</option>
          <option disabled>----</option>
          {searchDataResp.allMaslul.map((item) => (
            <option value={item.Maslol} key={item.Maslol}>
              {item.Maslol}
            </option>
          ))}
        </FormControl>
      </Form.Group>

      <Form.Group className="inputsWithLabel shanaIco iconAfter">
        <Form.Label style={{ color: "white" }}>שנה</Form.Label>
        <FormControl
          as="select"
          className="inputsWithLabel selectIcoBG"
          onChange={(e) => {
            setState(prev => ({ ...prev, yearChanged: true }));
            handleChangeSelect(e);
          }}
          name="YEAR"
          disabled={!state.YEAR}
          value={
            !state.yearChanged
              ? decodeURI(state.urlParams.year)
              : state.YEAR
          }
        >
          <option value="">בחרו שנה</option>
          <option disabled>----</option>
          <option value='תשפ"ה'>תשפ"ה</option>
          <option value='תשפ"ו'>תשפ"ו</option>
        </FormControl>
      </Form.Group>

      <Form.Group className="inputsWithLabel">
        <Form.Label style={{ color: "white" }}>תחום</Form.Label>
        <MultiSelectReact
          currentState={state}
          disabled={!state.Maslol}
          clearMulti={clearMulti}
          options={searchDataResp.allThum}
          handleChangeThum={handleChangeThum}
        />
      </Form.Group>
    </div>

    <div className="line inputs4">
      <div className="input3Cont inputs3">
        <Form.Group className="inputsWithLabel selectIco ezorIco iconAfter">
          <Form.Label style={{ color: "white" }}>איזור</Form.Label>
          <FormControl
            as="select"
            className="inputsWithLabel selectIcoBG"
            onChange={handleChangeSelect}
            name="ZoneName"
            value={state.ZoneName}
            disabled={!state.Maslol}
          >
            <option value="">בחרו איזור</option>
            <option disabled>----</option>
            {searchDataResp.allCity_Zone.map((item) => (
              <option value={item.City_Zone} key={item.City_Zone}>
                {item.City_ZoneName}
              </option>
            ))}
          </FormControl>
        </Form.Group>

        <Form.Group className="inputsWithLabel placeIco iconAfter">
          <Form.Label style={{ color: "white" }}>ישוב</Form.Label>
          <FormControl
            as="select"
            className="inputsWithLabel selectIcoBG"
            onChange={handleChangeSelect}
            name="City"
            value={state.City}
            disabled={!(state.Maslol && state.ZoneName)}
          >
            <option value="">בחרו ישוב</option>
            <option disabled>----</option>
            {searchDataResp.cities.map((item) => {
              if (state.ZoneName) {
                if (item.City_Zone === state.ZoneName) {
                  return (
                    <option
                      value={item.City_Value}
                      key={item.City_Value}
                    >
                      {item.City_Key}
                    </option>
                  );
                }
                return null;
              }
              return (
                <option value="" key={item.City_Value}>
                  {item.City_Key}
                </option>
              );
            })}
          </FormControl>
        </Form.Group>

        <Form.Group className="inputsWithLabel searchIco">
          <Form.Label style={{ color: "white" }}>
            חיפוש חופשי
          </Form.Label>
          <FormControl
            disabled={!state.Maslol}
            placeholder="לדוגמא: חינוך מיוחד"
            className="inputsWithLabel"
            onChange={handleChange}
            name="freeSearch"
            value={state.freeSearch}
            type="text"
          />
        </Form.Group>
      </div>
    </div>
  </div>
));

export default SearchUpPlaces;
