import React from 'react';
import face from './../../img/sherut-leumi/svg/social/face.svg';
import insta from './../../img/sherut-leumi/svg/social/insta.svg';
import tiktok from './../../img/sherut-leumi/svg/social/tiktok.svg';
import youtube from './../../img/sherut-leumi/svg/social/youtube.svg';
import whatsApp from './../../img/sherut-leumi/svg/social/whatsapp.svg';

const socialNetworks = [
  {
    name: 'facebook',
    url: 'https://www.facebook.com/agudasherut/',
    pic: face
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/aguda.sherut/',
    pic: insta
  },
  {
    name: 'tiktok',
    url: 'https://www.tiktok.com/@aguda.sherut',
    pic: tiktok 
  },
  {
    name: 'youtube',
    url: 'https://www.youtube.com/channel/UC6jDIDFUTAkHeP4I2ky20Mw',
    pic: youtube
  },
  {
    name: 'whatsapp',
    url: 'https://api.whatsapp.com/send/?phone=972507521223',
    pic: whatsApp
  }
];

const ShowSocialNetworks = () => {
  return (
    <div className="ShowSocialNetworks">
      <ul>
        {socialNetworks.map(({ name, url, pic }) => (
          <li key={name}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <img src={pic} alt={name} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ShowSocialNetworks;
