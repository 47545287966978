import React from "react";
import { RestUrls } from "../../../Components/-Helpers-/config";
import CloseAppIframe from "./CloseAppIframe";

const AvatarEditPage = ({ siteInfo }) => {
  const { isMobile } = siteInfo;
  const topPic = `${RestUrls.pagesPictures}/search/searchTop.jpg?v=1`;
  const m_topPic = `${RestUrls.pagesPictures}/register/m_registerTop.jpg?v=2`;

  return (
    <div className="DataSection editAvatarPageIndex">
      {!isMobile && (
        <img
          className="topPic full_width"
          src={topPic}
          alt="top"
          loading="lazy"
        />
      )}

      <div className="editAvatarPage">
        {!isMobile && (
          <header className="clear">
            <h1>עריכת אווטר</h1>
          </header>
        )}
        <CloseAppIframe {...siteInfo} />
      </div>
    </div>
  );
};

export default AvatarEditPage;
