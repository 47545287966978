import React, { Component } from "react";
import { Form, FloatingLabel } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0

import { FormValidator } from "./validation/FormValidator";
import { ValidationMessage } from "./validation/ValidationMessage";
import { ValidateForm } from "./validation/wholeFormValidation";

export default class FloatInputPassword extends Component {
  constructor(props) {
    super(props);

    const config = this.props;

    this.state = {
      inputValue: config.value ? config.value : "",
      isError: false,
      pass2: "",
    };

    this.rules = {
      inputValue: config.validationRules,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      //console.log(this.state);
    });
  };

  isOK = (value) => {
    const flag =
      (this.props.validationRules.required && this.state.inputValue) ||
      !this.props.validationRules.required
        ? true
        : false;

    if ((value.inputValue && value.inputValue.length > 0) || !flag) {
      this.setState(
        {
          isError: true,
        },
        () => this.props.updateValue({ [this.props.name]: "" })
      );
    } else {
      this.setState(
        {
          isError: false,
        },
        () =>
          this.props.updateValue({ [this.props.name]: this.state.inputValue })
      );
    }

    //console.log(value);
  };

  componentDidUpdate() {
    if (!this.state.isError && this.props.checkInputs) {
      this.isOK(this.state.inputValue);
      this.props.checked();
    }
  }

  handleChangeSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value }, function () {
      //console.log(this.state)
    });
  };

  render() {
    const config = this.props;

    return (
      <React.Fragment>
        <div className={"input inputType" + config.typeInput}>
          <FormValidator
            data={this.state}
            rules={this.rules}
            isOK={(value) => this.isOK(value)}
            validateForm={ValidateForm}
          >
            <FloatingLabel
              controlId={config.name}
              label={config.placeholder}
              /* className="mb-3" */
            >
              <Form.Control
                className={"customFloatInput " + config.cssClass}
                isInvalid={this.state.isError}
                /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                type={config.typeInput}
                name="inputValue"
                placeholder={config.placeholder}
                onChange={this.handleChange}
                value={this.state.inputValue}
              />
              <Form.Control.Feedback type="invalid">
                <ValidationMessage field="inputValue" />
              </Form.Control.Feedback>
            </FloatingLabel>
          </FormValidator>
        </div>

        {true ? (
          <div className={"input inputType" + config.typeInput}>
            <FormValidator
              data={this.state}
              rules={this.rules}
              isOK={(value) => this.isOK(value)}
              validateForm={ValidateForm}
            >
              <FloatingLabel
                controlId="pass2"
                label="אימות סיסמה"
                /* className="mb-3" */
              >
                <Form.Control
                  className={"customFloatInput " + config.cssClass}
                  isInvalid={this.state.isError}
                  /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                  type={config.typeInput}
                  name="pass2"
                  placeholder="אימות סיסמה"
                  onChange={this.handleChange}
                  value={this.state.pass2}
                />
              </FloatingLabel>
            </FormValidator>
          </div>
        ) : (
          false
        )}
      </React.Fragment>
    );
  }
}
