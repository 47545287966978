/* eslint-disable default-case */
import { RestUrls } from "../../Components/-Helpers-/config";
import { toast } from 'react-toastify';
import axios from 'axios';
import validator from "validator";

const API_BASE_URL = "https://vu-apiws.azurewebsites.net/api/";

const handleApiError = (error, setResponseData) => {
  console.error(error);
  setResponseData({});
  toast.error('שגיאה', { toastId: 'error' });
};

export const getFromApiSherutLeumi = async (url, sendObj, setLoading, setResponseData) => {
  setLoading(true);
  try {
    const response = await axios.post(`${RestUrls.sherutLeumiApi}${url}`, sendObj, {
      headers: { 'Content-Type': 'application/json' },
    });
    setResponseData(response);
  } catch (error) {
    handleApiError(error, setResponseData);
  } finally {
    setLoading(false);
  }
};

export const getClockApiMaakavStatus = async (url, sendObj, setLoading, setResponseData, setSelectedReportType, setTextAreaReport) => {
  setLoading(true);
  try {
    const { data } = await axios.post(`${API_BASE_URL}${url}`, sendObj, {
      headers: { 'Content-Type': 'application/json' },
    });

    if (data?.Result !== 'Success') {
      setResponseData(false);
      return toast.error('שגיאה בקריאת סטטוס');
    }

    setSelectedReportType(data?.IsCurrIn ? data?.Typ : false);
    setTextAreaReport(data?.IsCurrIn ? data?.MoreInfo : false);
    if (data?.IsCurrIn && data?.TimeF) {
      const timeF = new Date(data.TimeF);
      const timeValue = timeF.toLocaleTimeString('he-IL', { hour: '2-digit', minute: '2-digit', hour12: false });
      const timeAllValues = {
        hours: timeF.getHours(),
        minutes: timeF.getMinutes()
      };

      setResponseData({
        api: data,
        time: timeValue,
        timeAllValues,
        weekday: timeF.toLocaleDateString('he-IL', { weekday: 'long' }),
        day: timeF.toLocaleDateString('he-IL', { day: '2-digit', month: '2-digit', year: '2-digit' }),
      });
    }
  } catch (error) {
    handleApiError(error, setResponseData);
  } finally {
    setLoading(false);
  }
};

export const getClockApiMaakavOut = async (url, sendObj, setLoading, setResponseData, setSelectedReportType, setTextAreaReport, setPercentWorked, setLastExit = false) => {
  setLoading(true);
  try {
    const { data } = await axios.post(`${API_BASE_URL}${url}`, sendObj, {
      headers: { 'Content-Type': 'application/json' },
    });

    if (data?.Result === "Error") {
      return toast.error('שגיאה');
    }

    setResponseData(false);
    setSelectedReportType(false);
    setTextAreaReport(false);
    setPercentWorked(false);
    toast.success('היציאה התקבלה בהצלחה');

    if (setLastExit) {
      const now = new Date();
      setLastExit(now.toLocaleTimeString('he-IL', { hour: 'numeric', minute: '2-digit', hour12: false }));
    }
  } catch (error) {
    handleApiError(error, setResponseData);
  } finally {
    setLoading(false);
  }
};

export const getClockApiMaakavIn = async (url, sendObj, setLoading, setResponseData, setSelectedReportType, setTextAreaReport) => {
  setLoading(true);
  try {
    const { data } = await axios.post(`${API_BASE_URL}${url}`, sendObj, {
      headers: { 'Content-Type': 'application/json' },
    });

    if (data?.Result !== 'Success') {
      return toast.error('שגיאה');
    } else if (data?.ErrorMessage === 'No maakav record') {
      return toast.error('שגיאה. לא קיים מעקב.');
    } else {
      toast.success('הכניסה התקבלה בהצלחה');
      const userJ = JSON.parse(localStorage.getItem("userData"));
      const newSendObj = { IDNumber: userJ.IDNO, SessionKey: userJ.SessionKey };

      await getClockApiMaakavStatus('v2/volunteer/MaakavStatus', newSendObj, setLoading,
        setResponseData, setSelectedReportType, setTextAreaReport);
    }
  } catch (error) {
    handleApiError(error, setResponseData);
  } finally {
    setLoading(false);
  }
};

export const showCurrentMonthName = (monthNumber, currentYear, action = false) => {
  const startDate = new Date(currentYear, monthNumber - 1, 1);
  const options = action === 'monthNameOnly' ? { month: 'long' } : { month: 'long', year: 'numeric' };
  
  try {
    const monthYear = startDate.toLocaleDateString('he-IL', options);
    return monthYear !== 'Invalid Date' ? monthYear : '---';
  } catch (error) {
    console.error(error);
    toast.error('שגיאה');
    return '---';
  }
};

export const checkShowDays = (monthData, item) => {
  const now = new Date();
  const itemDate = new Date(monthData.Year, monthData.Month - 1, item.dayData.DOM);
  return itemDate <= now;
};

export const changeDay = (action, currentMonth, setCurrentMonth, currentYear, setCurrentYear, monthData = {}) => {
  const now = new Date();
  const currentDate = new Date(currentYear, currentMonth - 1);
  const flagMore = currentDate < now;

  switch (action) {
    case 'checkActive':
      return !flagMore;
    case 'checkEditMode':
      return !monthData?.ReportConfirmationDate;
    case 'back':
      if (currentMonth > 1) {
        setCurrentMonth(currentMonth - 1);
      } else {
        setCurrentYear(currentYear - 1);
        setCurrentMonth(12);
      }
      break;
    case 'forward':
      if (flagMore) {
        if (currentMonth < 12) {
          setCurrentMonth(currentMonth + 1);
        } else {
          setCurrentYear(currentYear + 1);
          setCurrentMonth(1);
        }
      }
      break;
  }
};

export const checkhasPerutDivuach = (selectedReportType, textAreaReport) =>
  (selectedReportType === '2' || selectedReportType === '3') || (textAreaReport && textAreaReport !== 'False');

export const checkTextAreaReportContent = (selectedReportType, textAreaReport) => {
  if (checkhasPerutDivuach(selectedReportType, textAreaReport)) {
    if (textAreaReport && validator.isNumeric(textAreaReport)) {
      toast.error('יש לרשום גם מלל');
      return false;
    }
    if (/^[A-Za-z0-9]*$/.test(textAreaReport)) {
      toast.error('אסור מילוי רק תווים באנגלית');
      return false;
    }
    return true;
  }
  return true;
};