import React, { useState, useEffect, useCallback } from "react";
import loader from "../../../img/preLoader.gif";
import getDataFromApi from "../../../Components/-Helpers-/api/getDataFromApi";
import SearchResults from "./SearchResults";

export default function MySayarot({ changeMenuright }) {
  const [sayarotResp, setSayarotResp] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const getMySayarot = useCallback(async () => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("userData"));
    const sendObj = {
      IDNO: user.IDNO,
      SessionKey: user.SessionKey,
      isRotemDev: true,
    };
    try {
      const getData = await getDataFromApi(
        "api/v2/Volunteer/syr/my",
        sendObj,
        "",
        "sayarot",
        "https://vu-apiws.azurewebsites.net/"
      );
      console.log(getData, "getData sayarotResp");
      setSayarotResp(getData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!sayarotResp) {
      getMySayarot();
    }
  }, [sayarotResp, getMySayarot]);

  const handlechange = useCallback((changes) => {
    changeMenuright(changes);
    setSayarotResp(prevState => {
      if (prevState && prevState.Sayarot) {
        const updatedSayarot = prevState.Sayarot.filter(
          (sayara) => sayara.ID !== changes.id
        );
        return { ...prevState, Sayarot: updatedSayarot };
      }
      return prevState;
    });
  }, [changeMenuright]);

  const renderSayarotList = () => {
    return sayarotResp.Sayarot.map((sayara) => (
      <div key={sayara.ID} className="ShowDataInRow">
        <header>
          <h2>{sayara.Name}</h2>
          <p>{sayara.Info}</p>
        </header>
        <ul className="iconsLine">
          <li>
            <span>רכז/ת: </span>
            <span>{sayara.CoordinatorName || 'N/A'}</span>
          </li>
          <li>
            <span>מקומות שנותרו: </span>
            <span>{sayara.FreeCount}</span>
          </li>
        </ul>
      </div>
    ));
  };

  return (
    <div className="SearchResults">
      {loading && (
        <img
          src={loader}
          alt="loader"
          className="loader active animate__animated animate__fadeIn"
        />
      )}

      {sayarotResp?.Sayarot?.length > 0 ? (
        <>
          {renderSayarotList()}
          <SearchResults
            changeMenuright={handlechange}
            sayarotData={sayarotResp.Sayarot}
          />
        </>
      ) : (
        <h2 className="noResults">אין תוצאות...</h2>
      )}
    </div>
  );
}
