import React from 'react'

import { filesTexts, objectsMyforms } from './fileFunctions'
import FileItem from './FileItem';
import { Grid } from '@material-ui/core';
import ExternalLink from './ExternalLink';
//import ReadAndSign from './ReadAndSign';

//console.log(filesTexts);

export default function ServiceDocs(props) {

    const { forms } = props

    const objectForm = objectsMyforms(forms);
    let userJ = JSON.parse(localStorage.getItem("userData"));

    //console.log('objectForm >', objectForm);

    return (
        <div className='docsCont'>

            <Grid className='lineForm' container spacing={2} alignItems="top" direction="row" justifyContent="center" >

                {/*  { objectForm.FormID1 && <Grid item md={3} >
                <FileItem formData = {objectForm.FormID1} texts={filesTexts.tz} userJ = {userJ} />
            </Grid> } */}

                {/* { objectForm.FormID2 && <Grid item md={3} >
                <ReadAndSign formData = {objectForm.FormID2} texts={filesTexts.takanon} userJ = {userJ} />
            </Grid> } */}
                {objectForm.FormID8 && <Grid item md={3} >
                    <ExternalLink formData={objectForm.FormID8} texts={filesTexts.diraProblem} userJ={userJ} />
                </Grid>}

                <Grid item md={3} >
                    <FileItem formData={{ FormID: "10" }} noHistoryForm={true} texts={filesTexts.ishurMahala} userJ={userJ} />
                </Grid>

                {objectForm.FormID16 && <Grid item md={3} >
                    <FileItem formData={objectForm.FormID16} texts={filesTexts.formId16} userJ={userJ} />
                </Grid>}

                {objectForm.FormID17 && <Grid item md={3} >
                    <FileItem formData={objectForm.FormID17} texts={filesTexts.formId17} userJ={userJ} />
                </Grid>}

                {objectForm.FormID18 && <Grid item md={3} >
                    <FileItem formData={objectForm.FormID18} texts={filesTexts.formId18} userJ={userJ} />
                </Grid>}

                {objectForm.FormID19 && <Grid item md={3} >
                    <FileItem formData={objectForm.FormID19} texts={filesTexts.formId19} userJ={userJ} />
                </Grid>}

                {objectForm.FormID99 && <Grid item md={3} >
                    <FileItem formData={objectForm.FormID99} texts={filesTexts.klali} userJ={userJ} />
                </Grid>}

        
                {objectForm.FormID71 && <Grid item md={3} >
                    <ExternalLink formData={objectForm.FormID71} texts={filesTexts.diraProblem} userJ={userJ} />
                </Grid>}
          
            </Grid>



        </div>
    )

}
