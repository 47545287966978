import React, { Component } from 'react'


import {Button} from 'react-bootstrap' //npm install react-bootstrap@next bootstrap@5.1.0

import getDataFromApi from '../../../Components/-Helpers-/api/getDataFromApi';
import loader from "../../../img/preLoader.gif";

import CustomFloatInput from '../../../Components/-Helpers-/forms/CustomFloatInput';
//import FloatInputPassword from '../../../Components/-Helpers-/forms/FloatInputPassword';

import ModalDefaul from '../../../Components/-Helpers-/ModalDefaul';
import { toast } from 'react-toastify';

export default class Emergency extends Component {

    constructor(props) {

        super(props);

        let user = JSON.parse(localStorage.getItem('userData'));
        

        let userApi = this.props.userData;
    
        //console.log(userApi);

        this.state = {
            idno :  user.IDNO,
            SessionKey : user.SessionKey,

            relativename : userApi.relativename,
            relativephone : userApi.relativephone,
            relativerelation : userApi.relativerelation,

            loading : false,
            btnSendClass : 'success',
            responseEditRegister : false
        }

        //console.log(this.state);
    
    
    
    }


    sendForm = (sendRules) => {


        this.setState({
            checkInputs : true,
            checkInputsPage : true
        })

        if(sendRules) {

            this.setState({
                loading: true
            });

            const sendObj = this.state;delete sendObj.responseEditRegister;
            delete sendObj.loading;delete sendObj.btnSendClass;
            delete sendObj.checkInputs;delete sendObj.checkInputsPage;


            this.sendtoAdmin('editUserData','users',sendObj,'responseEditRegister','users');

            //console.log(sendObj);
            
        } else {

            //console.log('NO send');
            //console.log(sendRules)
            toast.error('נא למלא שדות חובה');
            this.setState({
                btnSendClass : 'danger'
            })
        }

        

    }

    sendtoAdmin = (url,controller,objectToSend, setStateName = 'data', auth = 'all') => {

        this.setState({loading : true});

        const getData = getDataFromApi(url,objectToSend,controller,auth);

        getData.then(getData =>this.setState({ [setStateName] : getData, loading : false}, () => {

            //console.log(getData);

        }))
    }


    
    updateValue = (newValue) => {

        this.setState(newValue,()=>{
            //console.log(this.state);
        })
    }



    render() {

        const sendRules = 
            this.state.relativename &&
            this.state.relativephone &&
            this.state.relativerelation
        ;

        //const isMobile = this.props.isMobile ? this.props.isMobile : false;

        return (
            <div>
                <div className="firstRegister animate__animated animate__fadeIn">

                <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                <div className="inputs">

                    <div className="rows2  clear line">
                        <CustomFloatInput name='relativename' updateValue={this.updateValue} value={this.state.relativename} placeholder="שם איש קשר לשעת חירום" cssClass='' validationRules={ {required: true } } typeInput='text' checkInputs={this.state.checkInputs} checked={()=>this.setState({ checkInputs : false })} />
                        <CustomFloatInput name='relativephone' updateValue={this.updateValue} value={this.state.relativephone} placeholder="טלפון נייד" cssClass='' validationRules={ {required: true, phone: true } } typeInput='text' checkInputs={this.state.checkInputs} checked={()=>this.setState({ checkInputs : false })} />
                    </div>

                    <div className="clear line">
                        <CustomFloatInput name='relativerelation' updateValue={this.updateValue} value={this.state.relativerelation} placeholder="סוג קרבה" cssClass='' validationRules={ {required: true } } typeInput='text' checkInputs={this.state.checkInputs} checked={()=>this.setState({ checkInputs : false })} />
                    </div>

                    


                    <div className="text-center" >
                        <br/>
                        {/* disabled={!flagSendForm} */}
                        <Button className="sendBlueBtn" onClick={() => this.sendForm(sendRules)} size="lg" variant={sendRules ? 'success animate__animated animate__bounceIn' : this.state.btnSendClass} >שמירת שינויים</Button>

                    </div>

                </div>


                {this.state.responseEditRegister && this.state.responseEditRegister.error ? 

                < ModalDefaul variant="error" params={ {title: 'שגיאה', text : this.state.responseEditRegister.error } } callBack={() => this.setState({ responseEditRegister : false })}  />

                : false }

                {this.state.responseEditRegister && this.state.responseEditRegister.ok ? 

                < ModalDefaul params={ {title: 'תודה רבה', text : this.state.responseEditRegister.ok } } callBack={() => this.setState({ responseEditRegister : false })}  />

                : false }

                </div>
            </div>
        )
    }
}
