import React, { useState, useEffect } from "react";
import Pages from "./Pages";
import PagesRegister from "./PagesRegister";
import PagesUserConsole from "./PagesUserConsole";
import PagesMekomotSherut from "./PagesMekomotSherut";

import getDataFromApi from "../../Components/-Helpers-/api/getDataFromApi";

const SiteConnector = (props) => {
  const [loading, setLoading] = useState(false);
  const [userCredentialsResp, setUserCredentialsResp] = useState(false);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData && userData.length > 0) {
      const user = JSON.parse(userData);
      const sendObj = {
        idno: user.IDNO,
        SessionKey: user.SessionKey,
      };

      sendtoAdmin("checkActiveSession", "Register", sendObj, "userCredentialsResp", "all");
    }
  }, []);

  const sendtoAdmin = async (url, controller, objectToSend, setStateName = "data", auth = "all") => {
    console.log("SiteConnector");

    try {
      const response = await getDataFromApi(url, objectToSend, controller, auth);
      if (response && response.error) {
        localStorage.setItem("userData", "");
        window.open("/login", "_self");
      }
    } catch (error) {
      console.error("Error in API call:", error);
      localStorage.setItem("userData", "");
      window.open("/login", "_self");
    }
  };

  if (
    props.page === "register" ||
    props.page === "login" ||
    props.page === "reSendPass" ||
    props.page === "loginQueryUrl"
  ) {
    return <PagesRegister {...props} />;
  } else if (props.page === "userConsolePages") {
    return <PagesUserConsole {...props} />;
  } else if (props.page === "mekomotSherutPages") {
    return <PagesMekomotSherut {...props} />;
  } else {
    return <Pages {...props} />;
  }
};

export default SiteConnector;
