import React from 'react';
import StartStopPage from './StartStopPage';
import { RestUrls } from "../../Components/-Helpers-/config";
import './clockInOut.scss';
import ReportsPage from './ReportsPage';

const ClockInOutIndex = ({ siteInfo }) => {
  const topPic = `${RestUrls.pagesPictures}/search/searchTop.jpg?v=1`;
  const m_topPic = `${RestUrls.pagesPictures}/register/m_clockTop.jpg?v=1`;
  const isMobile = siteInfo.isMobile;

  const [selectedPage, setSelectedPage] = React.useState(false);

  return (
    <div className="DataSection clockInOutCont">
      <img 
        className="topPic full_width" 
        src={isMobile ? m_topPic : topPic} 
        alt="top"  
      />
      <PageContent 
        selectedPage={selectedPage} 
        setSelectedPage={setSelectedPage} 
      />
    </div>
  );
};

const PageContent = ({ selectedPage, setSelectedPage }) => {
  switch (selectedPage) {
    case 'reportsPage':
      return <ReportsPage setSelectedPage={setSelectedPage} />;
    default:
      return <StartStopPage setSelectedPage={setSelectedPage} />;
  }
};

export default ClockInOutIndex;