import React from 'react';

const TextDayShowSayarot = ({ isMobile }) => {
  return (
    <div className={`textDayShowSayarot ${isMobile ? 'mobileClass' : ''}`}>
      <div className='newText'>
        <h3>ההרשמה לסיירות פתוחה!</h3>
        <p>
          בחרי את הסיירות שמעניינות אותך, הירשמי ותתקדמי צעד נוסף אל עבר השירות שלך.
          <span>בהצלחה!</span>
        </p>
      </div>

      <div style={{ margin: '20px 0 0', background: 'white', padding: '5px 0' }}>
        <i className="fas fa-info-circle" aria-hidden="true"></i>
        <h2>לשאלות נוספות</h2>
        
        {isMobile ? (
          <a href="tel:1800233133">
            חייג/י למוקד של האגודה להתנדבות &raquo;
          </a>
        ) : (
          <p>
            חייג/י למוקד של האגודה להתנדבות:{' '}
            <strong>1800-233-133</strong>
          </p>
        )}
      </div>
    </div>
  );
};

export default TextDayShowSayarot;
