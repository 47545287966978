import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; //npm install react-router-dom

import MekomotSherutIndex from '../../Pages/MekomotSherut/MekomotSherutIndex';

const PagesMekomotSherut = (props) => {
    return (
        <React.Fragment>
            <Router>
                <Switch>
                    {/* MORE PAGES HERE!! */}
                    <Route path="/" render={() => <MekomotSherutIndex {...props} page='sherutPlacesIndex' />} />
                </Switch>
            </Router>
        </React.Fragment>
    );
};

export default PagesMekomotSherut;
