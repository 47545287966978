import React from "react";
import ContentWrapper from "./ContentWrapper";
import { RestUrls } from "../-Helpers-/config";

const PagesRegister = (props) => {
  const bgDesktop = RestUrls.pagesPictures;

  return props.isMobile ? (
    <div className="body-container PagesRegister Mobile">
      <ContentWrapper {...props} />
    </div>
  ) : (
    <div
      className="body-container PagesRegister Desktop"
      style={{
        backgroundImage: `url('${bgDesktop}register/bg.jpg?v=2')`,
      }}
    >
      {window.ReactNativeWebView === undefined && (
        <>
          <ContentWrapper {...props} />
          <a
            className="linkToSite"
            href="https://sherut-leumi.co.il/"
            target="_self"
            rel="noopener noreferrer"
          >
            לאתה האגודה
          </a>
        </>
      )}
    </div>
  );
};

export default PagesRegister;
