import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Button, Form } from "react-bootstrap";
import { map } from "lodash";
import { Switch } from "@material-ui/core";
import { toast } from "react-toastify";
import moment from "moment";

import { dateFromString } from "../clockInOut/editClockFunctions";
import { entryTraining, getTraning, iWillBeThere } from "./traningFunctions";
import loader from "../../img/preLoader.gif";

const TraningPage = () => {
  const [items, setItems] = useState(() => {
    const cachedItems = localStorage.getItem('trainingItems');
    return cachedItems ? JSON.parse(cachedItems) : [];
  });
  const [loading, setLoading] = useState(false);
  const userJ = useMemo(() => JSON.parse(localStorage.getItem("userData")), []);

  const loadTranings = useCallback(() => {
    const sendObj = { idno: userJ.IDNO, SessionKey: userJ.SessionKey };
    getTraning("v2/volunteer/training/dates", sendObj, setLoading, (newItems) => {
      setItems(newItems);
      localStorage.setItem('trainingItems', JSON.stringify(newItems));
    });
  }, [userJ.IDNO, userJ.SessionKey]);

  useEffect(() => {
    const cachedTimestamp = localStorage.getItem('trainingItemsTimestamp');
    const currentTime = new Date().getTime();
    if (!cachedTimestamp || currentTime - parseInt(cachedTimestamp) > 3600000) { // 1 hour
      loadTranings();
      localStorage.setItem('trainingItemsTimestamp', currentTime.toString());
    }
  }, [loadTranings]);

  const reloadItems = useCallback(() => {
    setItems([]);
    loadTranings();
  }, [loadTranings]);

  return (
    <div className="traningPage">
      <header className="clear">
        <h1>רשימת הכשרות</h1>
      </header>
      {loading && (
        <img
          src={loader}
          alt="loader"
          className="loader active animate__animated animate__fadeIn"
        />
      )}
      <ul className="list">
        {map(items, (item) => (
          <Item
            key={item.TrainingID}
            item={item}
            reloadItems={reloadItems}
            setLoading={setLoading}
          />
        ))}
      </ul>
    </div>
  );
};

const Item = ({ item, setLoading, reloadItems }) => {
  const [arrive, setArrive] = useState(() => {
    const cachedArrive = localStorage.getItem(`arrive_${item.TrainingID}`);
    return cachedArrive || "";
  });
  const userJ = useMemo(() => JSON.parse(localStorage.getItem("userData")), []);

  const dateShow = useMemo(() => {
    return item?.StartDate !== item?.EndDate
      ? `${dateFromString(item.StartDate)} עד ${dateFromString(item.EndDate)}`
      : dateFromString(item.StartDate);
  }, [item?.StartDate, item?.EndDate]);

  useEffect(() => {
    const dbValue = item?.TrainigDays?.[0]?.rsvp;
    const newArrive = dbValue === "TRUE" ? "אגיע" : dbValue === "FALSE" ? "לא אגיע" : "";
    setArrive(newArrive);
    localStorage.setItem(`arrive_${item.TrainingID}`, newArrive);
  }, [item?.TrainigDays, item.TrainingID]);

  const today = moment();
  const startDate = moment(item.StartDate, "YYYYMMDD");
  const endDate = moment(item.EndDate, "YYYYMMDD");
  const isTodayWithinRange = today.isBetween(startDate, endDate, null, "[]");
  const isPastDate = today.isAfter(endDate);
  const isFutureDate = today.isBefore(startDate);

  const sendValue = useCallback((value) => {
    const firstDay = moment(item?.TrainigDays?.[0]?.Date, "YYYYMMDD");
    const difference = firstDay.diff(today, "days");
    const valueChange = value === "אגיע" ? "TRUE" : "FALSE";

    const sendObj = {
      idno: userJ.IDNO,
      SessionKey: userJ.SessionKey,
      TrainingID: item?.TrainingID,
      RSVP: valueChange,
    };

    if (difference <= 3 && arrive === "אגיע" && valueChange === "FALSE") {
      toast.error("לא ניתן לבטל את אישור ההגעה. נא לפנות לרכז/ת");
    } else {
      setArrive(value);
      localStorage.setItem(`arrive_${item.TrainingID}`, value);
      iWillBeThere("v2/Volunteer/training/rsvp", sendObj, setLoading, reloadItems);
    }
  }, [arrive, item?.TrainingID, item?.TrainigDays, userJ.IDNO, userJ.SessionKey, setLoading, reloadItems]);

  return (
    <li className={item.rsvpRequired && !isTodayWithinRange ? "rsvpRequired" : "no_rsvpRequired"}>
      <div className="text">
        <h3>{item.Title}</h3>
        {item.rsvpRequired && !isPastDate && (
          isTodayWithinRange || isFutureDate ? (
            <div>
              <Button
                variant={arrive === "אגיע" ? "primary" : "outline-primary"}
                onClick={() => sendValue("אגיע")}
              >
                אגיע
              </Button>
              <Button
                variant={arrive === "לא אגיע" ? "danger" : "outline-danger"}
                onClick={() => sendValue("לא אגיע")}
              >
                לא אגיע
              </Button>
            </div>
          ) : (
            <Form.Control
              as="select"
              className="chooseArrive"
              onChange={(e) => sendValue(e.target.value)}
              value={arrive}
            >
              <option value="">האם אגיע?</option>
              <option value="אגיע">אגיע</option>
              <option value="לא אגיע">לא אגיע</option>
            </Form.Control>
          )
        )}
      </div>
      <div className="flexCont">
        {item.TrainigDays && map(item.TrainigDays, (day) => (
          <Day key={day.Date} day={day} item={item} setLoading={setLoading} reloadItems={reloadItems} />
        ))}
      </div>
      <p className="showDates">{dateShow}</p>
    </li>
  );
};

const Day = ({ day, setLoading, reloadItems, item }) => {
  const [isArrived, setIsArrived] = useState(() => {
    const cachedIsArrived = localStorage.getItem(`isArrived_${item.TrainingID}_${day.Date}`);
    return cachedIsArrived ? JSON.parse(cachedIsArrived) : day?.isArrived === true;
  });
  const today = moment().startOf("day");
  const dayDate = moment(day?.Date, "YYYYMMDD").startOf("day");
  const isPastDate = today.isAfter(dayDate);

  useEffect(() => {
    if (isPastDate && day?.isArrived === false) {
      setIsArrived(false);
      localStorage.setItem(`isArrived_${item.TrainingID}_${day.Date}`, 'false');
    }
  }, [isPastDate, day?.isArrived, item.TrainingID, day.Date]);

  const handleChange = useCallback(() => {
    const sendObj = {
      sessionKey: JSON.parse(localStorage.getItem("userData")).SessionKey,
      idno: JSON.parse(localStorage.getItem("userData")).IDNO,
      trainingID: item.TrainingID,
      isArrived: (!isArrived).toString()
    };
    entryTraining(
      "v2/Volunteer/training/entry",
      sendObj,
      setLoading,
      (newValue) => {
        setIsArrived(newValue);
        localStorage.setItem(`isArrived_${item.TrainingID}_${day.Date}`, JSON.stringify(newValue));
      },
      reloadItems
    );
  }, [isArrived, item.TrainingID, day.Date, setLoading, reloadItems]);

  if (!isPastDate) return null;

  return (
    <div className="day">
      <p>{dateFromString(day?.Date)}</p>
      <div className="switch">
        <span>הגעתי: </span>
        <Switch
          checked={isArrived}
          onChange={handleChange}
          color="primary"
          name="checkedB"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        {!isArrived && <span>לא הגעתי</span>}
      </div>
    </div>
  );
};

export default TraningPage;
