import React, { Component } from "react";
import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from "react-router-dom";
import BulletsMenu from "./BulletsMenu";
import logo from "../../img/sherut-leumi/logoNavRightNew.svg";
import ShowSocialNetworks from "./ShowSocialNetworks";
import AvatarMenuPic from "./closeApp/AvatarMenuPic";

export default class NavRight extends Component {
  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getItem("userData"));
    console.log('userData >',user);

    this.state = {
      loading: false,
      bounceClass: "",
      sayeretFlag: 0,
      sayarotNumber: user.Sayarot ? user.Sayarot.length : 0,
      thisUser: null,
    };
  }
  componentDidMount() {
    const { Category, InService } =
      localStorage.getItem("userData") &&
        localStorage.getItem("userData").length !== 0
        ? JSON.parse(localStorage.getItem("userData"))
        : { Category: "null", InService: "null" };
    this.setState({
      thisUser: {
        Category,
        InService,
      },
    });
    //console.log(this.state.thisUser, "this.state.thisUser.InServic");
  }
  componentDidUpdate() {
    //console.log(this.props);
    //console.log(this.state.thisUser.Category, "this.state.thisUser.Category");

    if (
      this.props.changeSayarot.changeSayeret &&
      parseInt(this.props.changeSayarot.changeSayeret) !==
      this.state.sayeretFlag
    ) {
      //console.log(this.props.changeSayarot);

      this.setState(
        {
          bounceClass: "animate__animated animate__bounceIn",
          sayeretFlag: parseInt(this.props.changeSayarot.changeSayeret),
          sayarotNumber: this.props.changeSayarot.count,
        },
        () => {
          //this.props.changeUpdated();
        }
      );
    }
  }

  render() {
    let currentPage = this.props.page;
    
    let user = JSON.parse(localStorage.getItem("userData"));

    //console.log(this.props);

    const isUser =
      localStorage.getItem("userData") &&
        localStorage.getItem("userData").length > 0
        ? true
        : false;

    //for hide items
    // const urlQuery = getAllUrlParams(window.location.href);

    //const host = window.location.host;
    //const isDevUser = (host.includes("localhost:") || host.includes(".vercel.app") || host.includes("10.0.0.192:") ) ? true : false ;

    return (
      <div className="site-menu">
        <div className="mainMenuTable">
          <div className="fixedPosition">
            {/*  <div className="clear">
            {isUser ? <Button href='?logout=1' style={{margin: '10px,10px,0,0'}} className="logOutBtn" variant="secondary" size="sm"><i className="fas fa-sign-out-alt" /></Button> : '' }
          </div> */}

            <header>
              {/* <a href="/userConsole" target="_self" > */}
              <a
                href="https://sherut-leumi.co.il/"
                target="_self"
                rel="noopener noreferrer"
              >
                <img src={logo} alt="האגודה להתנדבות" className="logoClient" />
              </a>

              {isUser ? (
                <div className="userData">

                  <AvatarMenuPic user ={user} />

                  <div className="textCont">
                    {user.Sex === "2" ? (
                      <h3>ברוכה הבאה {user.FirstName + (user.LastName && " " + user.LastName)}</h3>
                    ) : (
                      <h3>ברוך הבא {user.FirstName + (user.LastName && " " + user.LastName)}</h3>
                    )}
                  </div>
                </div>
              ) : (
                false
              )}
            </header>

            <nav className="mainMenuNav">
              {true && (
                <NavLink
                  activeClassName={
                    currentPage === "userData" ? "is-active" : ""
                  }
                  role="menuitem"
                  onClick={() => scroll.scrollToTop({ duration: 0 })}
                  to="/userConsole/data"
                >
                  <BulletsMenu name="userData" />
                  <span>פרטים אישיים</span>
                </NavLink>
              )}

              {this.state.thisUser &&
                this.state.thisUser.InService === true && (
                  <NavLink
                    activeClassName={
                      currentPage === "clockInOutIndex" ? "is-active" : ""
                    }
                    role="menuitem"
                    onClick={() => scroll.scrollToTop({ duration: 0 })}
                    to="/userConsole/clockInOut"
                  >
                    <BulletsMenu name="clockInOutIndex" />
                    <span>נוכחות</span>
                  </NavLink>
                )}

              <NavLink
                activeClassName={currentPage === "training" ? "is-active" : ""}
                role="menuitem"
                onClick={() => scroll.scrollToTop({ duration: 0 })}
                to="/userConsole/training"
              >
                <BulletsMenu name="userData" />
                <span>הכשרות</span>
              </NavLink>

              <NavLink
                activeClassName={currentPage === "files" ? "is-active" : ""}
                role="menuitem"
                onClick={() => scroll.scrollToTop({ duration: 0 })}
                to="/userConsole/files"
              >
                <BulletsMenu name="files" />
                <span>מסמכים</span>
              </NavLink>
              {/* this.state.thisUser &&
                ((this.state.thisUser.InService === false &&
                  this.state.thisUser.Category !== "3") ||
                  (this.state.thisUser.InService === false &&
                    this.state.thisUser.Category !== "4") ||
                  (this.state.thisUser.InService === false &&
                    this.state.thisUser.Category !== "5")) */}
              {true && (
                  <a role="menuitem" href="/sherutPlaces" target="_self">
                    <BulletsMenu name="sherutPlaces" />
                    <span>מקומות שרות</span>
                  </a>
                )}

{/*             
              {process.env.REACT_APP_SEASON_TOGGLE === "on" &&
                this.state?.thisUser &&
                this.state.thisUser.Category === "2" && ( */}
            
              {
                this.state?.thisUser &&
                this.state.thisUser.Category === "2" &&
                this.state.thisUser.InService === false && (
                  <NavLink
                    className={this.state.bounceClass}
                    activeClassName={
                      currentPage === "userIndex" ||
                        currentPage === "userSearch"
                        ? "is-active"
                        : ""
                    }
                    role="menuitem"
                    onClick={() => scroll.scrollToTop({ duration: 0 })}
                    to="/userConsole"
                  >
                    <BulletsMenu
                      name="bulletLev"
                      sayarotNumber={this.state.sayarotNumber}
                    />
                    <span>סיירות</span>
                  </NavLink>
                )}

                {
                this.state?.thisUser &&
                (this.state.thisUser.Category === "2" || this.state.thisUser.Category === "1") &&
                this.state.thisUser.InService === false && (
                  <NavLink
                    activeClassName={currentPage === "sorties" ? "is-active" : ""}
                    role="menuitem"
                    onClick={() => scroll.scrollToTop({ duration: 0 })}
                    to="/sorties"
                  >
                    <BulletsMenu name="userData" />
                    <span>מיונים</span>
                  </NavLink>
                )}

              <NavLink
                activeClassName={currentPage === "editAvatar" ? "is-active" : ""}
                role="menuitem"
                onClick={() => scroll.scrollToTop({ duration: 0 })}
                to="/userConsole/editAvatar"
              >
                <BulletsMenu name="userData" />
                <span>עריכת אווטאר</span>
              </NavLink>

              <NavLink
                activeClassName={currentPage === "contactUs" ? "is-active" : ""}
                role="menuitem"
                onClick={() => scroll.scrollToTop({ duration: 0 })}
                to="/userConsole/contactUs"
              >
                <BulletsMenu name="contact" />
                <span>צור קשר</span>
              </NavLink>

              <a role="menuitem" href="?logout=1" target="_self">
                <BulletsMenu name="logOff" />
                <span>להתנתק</span>
              </a>
            </nav>

            <div className="credit">
              <ShowSocialNetworks />

              <a
                href="https://waveproject.co.il/"
                target="_blank"
                rel="noopener noreferrer"
              >
                
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
