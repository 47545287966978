import React, { Component } from 'react'
//import loader from "../../../img/preLoader.gif";


export default class ShowFilters extends Component {

    constructor(props) {
        super(props)
    
        let states = this.props.currentState;

        this.state = {
            
            freeSearch : states.freeSearch,
            rakaz : states.rakaz,
            city : states.city,
            sayeretId : states.sayeretId,

            updateFilters : false

        }

        console.log(states);
    }
    
    handleClick = (param) => {

        this.setState({
            [param] : '',

        }, () => {

            this.props.callBack(param);

        })
        
    }
    
    componentDidUpdate () {

        let states = this.props.currentState;

        /* console.log(this.props.updateFilters);
        console.log(this.state.updateFilters); */


        if(this.props.updateFilters !== this.state.updateFilters) {

            this.setState({

                freeSearch : states.freeSearch,
                rakaz : states.rakaz,
                city : states.city,
                sayeretId : states.sayeretId,                

                updateFilters : this.props.updateFilters
            });

        }

    }


    render() {

        //console.log(this.props.searchData);

        let idValue = this.state.rakaz;

        let rakazFromId = '';
        let cityFromId = '';

        if(this.props.searchData && this.props.searchData.rakazot) {

            rakazFromId = this.props.searchData.rakazot.filter(function(desc) {
                return desc.Rak_Key === idValue;
            });

            idValue = this.state.city;

            if(this.props.searchData.cities) {
                cityFromId = this.props.searchData.cities.filter(function(desc) {
                    return desc.City_Key === idValue;
                });
            }

        }


        return (
            <div className="showFilters">
                <ul>

                    <li className="title">תוצאות חיפוש:</li>

                    {console.log(this.state)}

                    {this.state.sayeretId ? <li>
                        <button onClick={ () => this.handleClick('sayeretId')  }>מק"ט סיירת: {this.state.sayeretId}</button>
                    </li> : false }

                    {this.state.freeSearch ? <li>
                        <button onClick={ () => this.handleClick('freeSearch')  }>{this.state.freeSearch}</button>
                    </li> : false }

                    {this.state.rakaz && rakazFromId[0] ? <li>
                        <button onClick={ () => this.handleClick('rakaz')  }>{ rakazFromId[0].Rak_Value }</button>
                    </li> : false }

                    {this.state.city && cityFromId[0] ? <li>
                        <button onClick={ () => this.handleClick('city')  }>{ cityFromId[0].City_Value }</button>
                    </li> : false }

                </ul>
            </div>
        )
    }
}
