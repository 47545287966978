import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";
import SiteConnector from "./SiteConnector";
import { getAllUrlParams } from "../-Helpers-/UrlParameters";
import MekomotSherutSearchOnly from "../../Pages/MekomotSherut/MekomotSherutSearchOnly";

const GetPage = ({ location }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const urlParams = getAllUrlParams(window.location.search);

  useEffect(() => {
    const checkLoginStatus = () => {
      const userData = localStorage.getItem("userData");
      setIsLoggedIn(!!userData && userData.length > 0);
    };

    window.addEventListener("storage", checkLoginStatus);
    checkLoginStatus();

    return () => {
      window.removeEventListener("storage", checkLoginStatus);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("rakazid");
    localStorage.removeItem("sayeretid");
    setIsLoggedIn(false);
    window.location.href = "/login";
    return <Redirect to="/login" />;
  };

  const handleLogin = () => {
    const userData = JSON.stringify({ SessionKey: "s" });
    try {
      localStorage.setItem("userData", userData);
      localStorage.removeItem("rakazid");
      localStorage.removeItem("sayeretid");
      setIsLoggedIn(true);
      return <Redirect to="/userConsole" />;
    } catch (error) {
      console.error("Error setting localStorage:", error);
      // Handle the error, perhaps by using session storage or a different state management solution
    }
  };

  if (location.search === "?logout=1") {
    return handleLogout();
  } else if (location.search === "?login=1") {
    return handleLogin();
  }

  const renderComponent = (page) => (
    <SiteConnector
      search={location.search}
      page={page}
      isMobile={isMobile}
    />
  );

  return (
    <Router>
      <Switch>
        {urlParams?.iframe === "1" && urlParams?.menuonly === "1" ? (
          <Route path="/" component={() => <MekomotSherutSearchOnly isMobile={isMobile} />} />
        ) : urlParams?.iframe === "1" ? (
          <Route path="/" component={() => renderComponent("mekomotSherutPages")} />
        ) : isLoggedIn ? (
          <>
            <Route path="/sherutPlaces" component={() => renderComponent("mekomotSherutPages")} />
            <Route path="/" component={() => renderComponent("userConsolePages")} />
          </>
        ) : (
          <>
            <Route path="/register" component={() => renderComponent("register")} />
            <Route path="/login" component={() => renderComponent("login")} />
            <Route path="/reSendPass" component={() => renderComponent("reSendPass")} />
            <Route path="/loginQueryUrl" component={() => renderComponent("loginQueryUrl")} />
            <Route path="/sherutPlaces" component={() => renderComponent("mekomotSherutPages")} />
            <Route path="/" component={() => renderComponent("login")} />
          </>
        )}
      </Switch>
    </Router>
  );
};

export default GetPage;
