import React, { useEffect } from "react";
import HomePage from "../../Pages/HomePage";
import RegisterIndex from "../../Pages/Register/RegisterIndex";
import UserConsoleSearchIndex from "../../Pages/UserConsole/UserConsoleSearchIndex";
import UserConsoleDataIndex from "../../Pages/UserConsole/UserConsoleDataIndex";
import SherutPlacesIndex from "../../Pages/MekomotSherut/SherutPlacesIndex";
import FilesPage from "../../Pages/UserConsole/files/FilesPage";
import ClockInOutIndex from "../../Pages/clockInOut/ClockInOutIndex";
import TrainingIndex from "../../Pages/training/TrainingIndex";
import SortiesIndex from "../../Pages/sorties/SortiesIndex";
import ContactUsIndex from "../../Pages/contactUs/ContactUsIndex";
import AvatarEditPage from "../../Pages/UserConsole/closeApp/AvatarEditPage";

const ContentWrapper = ({ page, info, changeMenuright }) => {
  useEffect(() => {
    const handleCameraPermissions = () => {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          // Camera access granted
        })
        .catch(error => {
          console.error("Camera access error:", error);
        });
    };

    window.addEventListener("message", handleCameraPermissions);

    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const isLoggedIn = userData && Object.keys(userData).length > 0 && !["login", "register", "reSendPass"].includes(page);

    const message = JSON.stringify({
      isLoggedIn: isLoggedIn.toString(),
      Category: userData.Category || "2",
      InService: userData.InService || "null",
    });

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(message);
    } else {
      window.postMessage(message);
    }

    return () => {
      window.removeEventListener("message", handleCameraPermissions);
    };
  }, [page]);

  const renderContent = () => {
    const isDevUser = true; // This should be determined dynamically based on your app's logic

    switch (page) {
      case "index":
        return <HomePage siteInfo={{ info }} />;
      case "register":
      case "login":
      case "loginQueryUrl":
      case "reSendPass":
        return <RegisterIndex siteInfo={{ info }} page={page} />;
      case "userIndex":
        return <UserConsoleSearchIndex siteInfo={{ info }} changeMenuright={changeMenuright} />;
      case "userSearch":
        return <UserConsoleSearchIndex siteInfo={{ info }} />;
      case "userData":
        return <UserConsoleDataIndex siteInfo={{ info }} />;
      case "files":
        return <FilesPage siteInfo={{ info }} />;
      case "clockInOutIndex":
        return isDevUser ? <ClockInOutIndex siteInfo={{ info }} /> : <h2 style={{ color: "red" }}>שגיאה</h2>;
      case "training":
        return isDevUser ? <TrainingIndex siteInfo={{ info }} /> : <h2 style={{ color: "red" }}>שגיאה</h2>;
      case "sorties":
        return <SortiesIndex siteInfo={{ info }} />;
      case "contactUs":
        return <ContactUsIndex siteInfo={{ info }} />;
      case "editAvatar":
        return <AvatarEditPage siteInfo={{ info }} />;
      case "sherutPlacesIndex":
        return <SherutPlacesIndex siteInfo={{ info }} />;
      default:
        return <div>NO PAGE</div>;
    }
  };

  return <>{renderContent()}</>;
};

export default ContentWrapper;
