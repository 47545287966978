import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { isMobile } from "react-device-detect";
import GetPage from "./Components/loginPages/getPage";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/animateNew.css";
import "./css/site.css";
import "./css/site_pages.css";
import "./css/mobile.css";
import "react-toastify/dist/ReactToastify.css";

const App = () => (
  <>
    <Router>
      <Switch>
        <Route path="/" component={GetPage} />
      </Switch>
    </Router>

    <ToastContainer
      limit={1}
      position={isMobile ? "top-center" : "top-left"}
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  </>
);

export default App;
