import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { RestUrls } from "../../Components/-Helpers-/config";

const ContactUs = ({ siteInfo }) => {
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data, "onSubmit");

    setSending(true);

    try {
      const userJ = JSON.parse(localStorage.getItem("userData"));
      const sessionKey = userJ?.SessionKey;

      const requestData = {
        ...data,
        SessionKey: sessionKey,
      };

      console.log("requestData", requestData);

      const res = await axios.post(
        "https://vu-apiws.azurewebsites.net/api/v2/Volunteer/contactus",
        JSON.stringify(requestData),
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      console.log("response", res);

      if (res?.data?.Result === "Error") {
        toast.error("שגיאה");
      } else if (res?.data?.ErrorMessage) {
        toast.error(res.data.ErrorMessage);
      } else {
        toast.success("השינויים נשמרו בהצלחה");
        setSent(true);
      }
    } catch (error) {
      toast.error("שגיאה", {
        toastId: "error",
      });
      console.error(error);
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="DataSection trainingPageIndex">
      <img
        className="topPic full_width"
        src={
          siteInfo.isMobile
            ? `${RestUrls.pagesPictures}/register/m_registerTop.jpg?v=2`
            : `${RestUrls.pagesPictures}/search/searchTop.jpg?v=1`
        }
        alt="top"
      />

      <div style={{ display: "grid", placeItems: "center" }}>
        {!sent ? (
          <form
            style={{ maxWidth: "713px", margin: "0 auto" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div
              className="px-5 mt-5"
              style={{ textAlign: "right", width: "100%" }}
            >
              <div>
                <h1
                  style={{
                    textAlign: "right",
                    fontWeight: "700",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  צרו קשר
                </h1>
                <h5 style={{ margin: "10px 0", padding: "0" }}>
                  מלאו את הפרטים ונציג מטעמנו יחזור אליכם בהקדם
                </h5>
                <br />
              </div>
            </div>

            <div className="row px-5">
              <div className="col-md-6 col-sm-12 my-1">
                <label htmlFor="fullName">שם מלא</label>
                <input
                  disabled={sending}
                  {...register("fullName", {
                    required: "נא להזין שם מלא",
                  })}
                  type="text"
                  className="form-control"
                  id="fullName"
                  aria-describedby="emailHelp"
                />
                {errors?.fullName && (
                  <p className="text-danger">{errors.fullName.message}</p>
                )}
              </div>
              <div className="col-md-6 col-sm-12 my-1">
                <label htmlFor="idNumber">תעודת זהות</label>
                <input
                  disabled={sending}
                  {...register("idNumber", {
                    required: "נא להזין תעודת זהות",
                    validate: isIsraeliIdNumber,
                  })}
                  type="text"
                  className="form-control"
                  id="idNumber"
                  aria-describedby="emailHelp"
                />
                {errors?.idNumber && (
                  <p className="text-danger">{errors.idNumber.message}</p>
                )}
              </div>
              <div className="col-md-6 col-sm-12 my-1">
                <label htmlFor="email">איימיל</label>
                <input
                  disabled={sending}
                  {...register("email", {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                />
                {errors?.email && (
                  <p className="text-danger">{errors.email.message}</p>
                )}
              </div>
              <div className="col-md-6 col-sm-12 my-1">
                <label htmlFor="telephone">טלפון / נייד</label>
                <input
                  disabled={sending}
                  {...register("telephone", {
                    required: "נא להזין מספר טלפון",
                    pattern: {
                      value: /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/,
                      message: "מספר טלפון לא תקין",
                    },
                  })}
                  type="text"
                  className="form-control"
                  id="telephone"
                  aria-describedby="emailHelp"
                />
                {errors?.telephone && (
                  <p className="text-danger">{errors.telephone.message}</p>
                )}
              </div>
              <div className="form-group">
                <div className="col-md-12 col-sm-12 my-1">
                  <label htmlFor="subject">נושא</label>
                  <select
                    disabled={sending}
                    {...register("subject", {
                      required: "נא לבחור נושא",
                    })}
                    className="form-control"
                    id="subject"
                  >
                    <option value=""></option>
                    <option value="אישורי שרות">אישורי שרות</option>
                    <option value="דמי כיס">דמי כיס</option>
                    <option value="חיפוש מקום שרות שנה נוכחית">
                      חיפוש מקום שרות שנה נוכחית
                    </option>
                    <option value="חיפוש מקום שרות שנה הבאה">
                      חיפוש מקום שרות שנה הבאה
                    </option>
                    <option value="טפסים">טפסים</option>
                    <option value="כללי">כללי</option>
                  </select>
                  {errors?.subject && (
                    <p className="text-danger">{errors.subject.message}</p>
                  )}
                </div>
              </div>
              <div className="col-md-12 col-sm-12 my-1">
                <label htmlFor="content">תוכן הפניה</label>
                <textarea
                  disabled={sending}
                  {...register("content")}
                  className="form-control"
                  id="content"
                  aria-describedby="emailHelp"
                />
              </div>
              <div>
                <br />
                <button
                  disabled={sending}
                  style={{ backgroundColor: "#1991d0" }}
                  className="btn btn-success w-100"
                  type="submit"
                >
                  {sending ? "שולח" : "צור איתנו קשר"}
                </button>
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </form>
        ) : (
          <div
            style={{ display: "grid", placeItems: "center" }}
            className="mt-3"
          >
            <h1>הפנייה נשלחה בהצלחה</h1>
            <h1>ניצור איתך קשר בהקדם האפשרי</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUs;

const isIsraeliIdNumber = (id) => {
  id = String(id).trim();
  if (id.length > 9 || isNaN(id)) return false;
  id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
  return (
    Array.from(id, Number).reduce((counter, digit, i) => {
      const step = digit * ((i % 2) + 1);
      return counter + (step > 9 ? step - 9 : step);
    }) %
      10 ===
    0
  );
};
