import React, { useState, useEffect } from "react";
import axios from "axios";
import { RestUrls } from "../../../Components/-Helpers-/config";

const AvatarMenuPic = () => {
  const [avatarPicture, setAvatarPicture] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData"));
    getAvatarImage({ idno: user.IDNO, token: RestUrls.Code }, setAvatarPicture);
  }, []);

  if (!avatarPicture?.base64Pic) {
    return <DefaultPic />;
  }

  return (
    <div className="avatarPicture">
      <img src={avatarPicture.base64Pic} alt="תמונת אווטאר" />
    </div>
  );
};

const DefaultPic = () => {
  const bgDesktop = RestUrls.img;

  return (
    <figure>
      <div
        className="userPic"
        style={{
          backgroundImage: `url('${bgDesktop}default/noUser.png')`,
        }}
      />
    </figure>
  );
};

const getAvatarImage = async (sendObj, setAvatarPicture) => {
  try {
    const response = await axios.post(
      `${RestUrls.baseApiUrl}users/getAvatarImage`,
      sendObj,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    setAvatarPicture(response.data);
  } catch (error) {
    console.error("Error fetching avatar image:", error);
  }
};

export default AvatarMenuPic;
