import React from 'react';
import ContentWrapper from './ContentWrapper';

const Pages = ({ isMobile, ...props }) => {
    const containerClass = `body-container ${isMobile ? 'Mobile' : 'Desktop'}`;
    const menuText = isMobile ? 'MENU MOBILE' : 'MENU Desktop';
    const footerText = isMobile ? 'FOOTER MOBILE' : 'FOOTER Desktop';

    return (
        <div className={containerClass}>
            <h2>{menuText}</h2>
            {isMobile ? (
                <h1>Mobile PAGE</h1>
            ) : (
                <ContentWrapper {...props} />
            )}
            <h2>{footerText}</h2>
        </div>
    );
};

export default Pages;
