import { Grid } from '@material-ui/core';
import React,{ useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import loader from "../../../img/preLoader.gif";

import link from '../../../img/sherut-leumi/svg/files/link.svg';
import { filesTexts } from './fileFunctions'


export default function OpenPDFfiles(props) {

    const {data} = props;


    
    const [showModal, setShowModal] = useState(false);


    const textname = `link${data.TypeDocument}`;
    const texts = filesTexts[textname];




    
  
  return (
    <>

  <Grid item md={3} >
      <div className='fileItem'>

          <img
                src={loader}
                alt="loader"
                className={"loader"}
          />
        
          <div className='header' >
              <h3>
                <img src={link} alt='link' />
                <span>{texts.title}</span>
              </h3>
              {/* <p>{texts.filesType}</p> */}
          </div>

          <div className='blueQuad externalLink fullBorder' >
              {/* <div className='linkP'>(לינק חיצוני)</div> */}
              <button style={{marginTop: '20px'}} className='blueBtn' onClick={ () => setShowModal(true)  } >לצפייה</button>
          </div>

      </div>
    </Grid>
    
    
    <Modal
                className={ 'animate__animated animate__fadeInDown animate__faster modalSite filesModal modalHtml' }
                animation={false}
                onHide={() => setShowModal(false)}
                /* size="sm" // בגודל קטן!!! */
                size="lg" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={showModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                
            <Modal.Body>

                <Button className="closeBtn" onClick={() => setShowModal(false)} variant="secondary" >X</Button>

                <div className='showHtml' dangerouslySetInnerHTML={{ __html: data.Html }} />

                <Grid className="btnCont" container spacing={2}  alignItems="center" direction="row" justifyContent="center" >
                
                    <Grid item md={2} >
                        <Button variant="secondary" style={{width: '100%'}} className="closeBtnBig" onClick={() => setShowModal(false)} >סגירה</Button>
                    </Grid>

                </Grid>

            </Modal.Body>
        </Modal> 
    </>
    
  )
}
