import React, { Fragment, useEffect, useState } from 'react'
import { useRef } from 'react';

import infoRed from "../../img/icons/clockPage/infoRed.svg";

import add from "../../img/icons/clockPage/add.svg";
import question from "../../img/icons/clockPage/question.svg";
import { map, size, some } from 'lodash';
import OpenCLoseTextAreaReport from './OpenCLoseTextAreaReport';

import loader from "../../img/preLoader.gif";
import { checkData4Save, getAbsenceList, sendChangesToApi } from './editClockFunctions';

import failed from "../../img/icons/clockPage/failed.svg";

import { Form } from 'react-bootstrap'
import { toast } from 'react-toastify';
//import note from '../../img/icons/clockPage/note.svg'

export default function EditMyDay(props) {

    //const {item} = props //closeMe

    const [currenPage, ] = useState('editHours') //setCurrenPage

    //console.log('item', item);
    

    return (
        <div className='editMyDay'>

            {currenPage === 'editHours' && <EditHours {...props} />}

        </div>
    )

}

function EditHours(props) {

    const {item, monthData, closeMe, reloadData } = props //closeMe

    const [showInfo, setShowInfo] = useState(false);

    const [loading, setLoading] = useState(false);
    const [absenceList, setAbsenceList] = useState(false);

    //console.log('item', item);

    //console.log(' monthData',  monthData);

    //value
    const [absence, setAbsence] = useState(item?.isAbsence ? item.isAbsence : '');

    //show only
    const [showAbsence, setShowAbsence] = useState(item?.isAbsence ? true : false);

    
    let userJ = JSON.parse(localStorage.getItem("userData"));

    const [inOutDays, setInOutDays] = useState(item.items);

     //absenceList
     useEffect(() => {
        
        if(!loading && !absenceList && showAbsence) {

            /* const sendObj = {
                idno: userJ.IDNO,
                SessionKey: userJ.SessionKey,
                Date:'20220714'} */
            
            const date = monthData?.Year + monthData?.Month + item?.dayData.DOM;

            //console.log('date', date)

            const sendObj = {idno: userJ.IDNO,SessionKey: userJ.SessionKey,Date: date}
            getAbsenceList('v2/volunteer/Available_reasons', sendObj, setLoading,setAbsenceList);

            
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAbsence])


    const addDay = () => { 

        const newId = monthData?.ReportID + '_' + parseInt(size(inOutDays) + 1);

        const newItem = {

            id: newId,
            in: '',
            out: '',
            api: {
                LateReportMtnF: false,
                LateReportMtnT: false,
                LateReportRakF: false,
                LateReportRakT: false,
                MoreInfo: "",
                TimeF: "",
                TimeT: "",
                Typ: ""

            }
        }

        toast.success('אפשרויות נוספות לדיווח בסוף הרשימה');
        setInOutDays(inOutDays.concat(newItem));

     }
     
    // console.log('inOutDays', inOutDays);

    const cancelChangesHandler = () => { 

        toast.warning('השינוים לא נשמרו');
        closeMe('bottom', false);

    }

    const saveChanges = () => { 

        //toast.warning('אין API');

        const checkSave = checkData4Save(allChanges);
        
        if(checkSave) {
            const sendObj = {IDNumber: userJ.IDNO,SessionKey: userJ.SessionKey,}
            sendChangesToApi('v2/volunteer/maakavupdate', sendObj, setLoading,allChanges,closeMe,reloadData);

            //sendChangesToApi(allChanges)
            
        }

        
        //console.log('checkSave', checkSave);

    }

    const [allChanges, setAllChanges ] = useState(deafaultValues(monthData,item));

    const [areChanges, setAreChanges] = useState(false);

    //change absence
   useEffect(() => {

        //console.log('absence');
        
        
        const oldObj = allChanges.Dates[0];
        oldObj.Reson = absence;
        
        setAllChanges({...allChanges, Dates : [oldObj] } )
     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [absence])
    
    const changeAbsence = (value) => {
        
        setAreChanges(true);
        setAbsence(value);

     }


    //change in out type and textArea
    const changeMyDay = (changes) => { 

        setAreChanges(true);

        //console.log('allChanges > ', allChanges);

        const prevObj = allChanges.Dates;
        const oldArray = prevObj[0].Times;

        if( some(oldArray, { id : changes.id }) ) {
            
            var newArr = map(oldArray, function(a) {
                return a.id === changes.id ? {...changes} : a;
            });

            prevObj[0].Times = newArr;
        
        } else {
            //console.log('change!!');
            prevObj[0].Times = oldArray.concat(changes);

        }

       
    }

    //console.log('allChanges > ', allChanges);
    const cancelAbs = () => { 

        setAreChanges(true);
        setAbsence('');
        setShowAbsence(false);
        
    }

    const [noEditQuota, setNoEditQuota] = useState(false);
    //noEditQuota
    useEffect(() => {

      
       if (
            (monthData?.LateReportUsed >= monthData?.LateReportQuota) && 
            !userJ?.AttendanceReportOnline  ) {

            setNoEditQuota(true);
            toast.error('מכסת הדיווחים באיחור נוצלה במלואה. נא לפנות לרכזת להמשך טיפול');
        } 

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    return(
        <Fragment>
            
            <img  src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />
            {/* onClick={()=> closeMe('bottom',false) } */}

            <div className='headerOpenDown'>
                <h2>דיווח ידני</h2>
                <p>{item.title}</p>
            </div>

            { !userJ?.AttendanceReportOnline &&
             <div className={`reportEditCount ${noEditQuota ? 'animate__animated animate__flash animate__delay-2s' : ''}`}>
                <img onClick={ ()=>setShowInfo(!showInfo) } src={ infoRed } alt='infoRed' />
                <strong style={ noEditQuota ? {color: 'red'} : {} }>נשארו עוד <span>10</span> מתוך 10 דיווחים באיחור</strong>
            </div> }

            <div className='buttonsEditReportDay'>

                <button onClick={ addDay }>
                    <img src={add} alt='add' />
                    <span>הוספת דיווח</span>
                </button>

                <button onClick={()=> setShowAbsence(!showAbsence)}>
                    <img src={question} alt='question' />
                    <span>סיבת העדרות</span>
                </button>

            </div>

            <div className='daysitemsCont'>

                {showInfo ? <ShowInfo monthData = {monthData} setShowInfo = {setShowInfo} /> :

                <Fragment>

                    {(showAbsence && absenceList) && <div className='isAbsence animate__animated animate__fadeIn'>

                        <h3>סיבת העדרות: </h3>

                        <div className='selectCont'>

                            {/* <img src={note} alt='note' /> */}

                            <Form.Control 
                                as="select"
                                className={"chooseReportSelect"}
                                //isInvalid={showSelectError}
                                //disabled={MaakavStatus?.api?.IsCurrIn}
                                /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                                //type={ config.typeInput }
                                //name='reportType'
                                onChange={(e) => changeAbsence(e.target.value) } 
                                value={absence}
                                //onFocus={this.populateSelect}
                            >
                                <option value="">סיבת העדרות</option>
                                
                                {map(absenceList, item => {

                                    return(<option value={item} key= {item} >{item}</option>)

                                })}

                            </Form.Control>

                        </div>

                        { absence && <button className='cancelAbs' onClick={cancelAbs} >
                            <img src={failed} alt='ביטול סיבת העדרות' />
                            <span>לחצו לביטול סיבת העדרות</span>
                        </button> }

                    </div> }

                    {map(inOutDays, dayItem => {

                        return(<DayItem 
                            key={ dayItem.id } 
                            dayItem={ dayItem } 
                            monthData={monthData}
                            item={item}
                            changeMyDay={(changes) => changeMyDay(changes)} 
                        />)

                    })}

                </Fragment> }

            </div>
            
            {!noEditQuota ? <div className='saveChangesBtns'>
                {areChanges && <button className='saveBtn' onClick={ saveChanges }>שמירת שינויים</button>}
                <button className='cancelBtn' onClick={ cancelChangesHandler }>
                    { !areChanges ? <span>סגירת חלון</span> : <span>ביטול שינויים</span> }
                </button>
            </div> : <div className='saveChangesBtns'>
                <button className='cancelBtn' onClick={ cancelChangesHandler }>סגירת חלון</button>
            </div> }

        </Fragment>        
    )
}


function DayItem(props) {

    const {dayItem, changeMyDay, monthData, item} = props

    const refIn = useRef(null);
    const refOut = useRef(null);

    const [ dayIn, setDayIn ] = useState(dayItem?.in);
    const [ dayOut, setDayOut ] = useState(dayItem?.out);

    const [selectedReportType, setSelectedReportType] = useState(false)
    const [textAreaReport, setTextAreaReport] = useState(false)

    //console.log('dayItem', dayItem);

    //send changes
    useEffect(() => {
        // Get the date from monthData and item
        const year = monthData?.Year;
        const month = monthData?.Month?.padStart(2, '0');
        const day = item?.dayData?.DOM?.padStart(2, '0');
        const date = `${year}-${month}-${day}`;
        
        // Get timezone offset in minutes and convert to hours:minutes format
        const now = new Date();
        const tzOffset = -now.getTimezoneOffset();
        const tzHours = Math.floor(Math.abs(tzOffset) / 60);
        const tzMinutes = Math.abs(tzOffset) % 60;
        const tzString = `${tzOffset >= 0 ? '+' : '-'}${tzHours.toString().padStart(2, '0')}:${tzMinutes.toString().padStart(2, '0')}`;
        
        const dayInTime = dayIn ? `${date}T${dayIn}:00${tzString}` : '';
        const dayOutTime = dayOut ? `${date}T${dayOut}:00${tzString}` : '';

        const newItemObj = {

            id : dayItem.id,
            //api : dayItem.api,
            TimeF: dayInTime,
            TimeT: dayOutTime,
            Typ: selectedReportType,
            MoreInfo: textAreaReport,
            LateReportMtnF: dayItem.api.LateReportMtnF,
            LateReportRakF: dayItem.api.LateReportRakF,
            LateReportMtnT: dayItem.api.LateReportMtnT,
            LateReportRakT: dayItem.api.LateReportRakT,
            
        }

        //console.log('newItemObj',newItemObj);

        changeMyDay(newItemObj);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dayIn,dayOut,selectedReportType,textAreaReport])
    
    const  setInputFocus = (inOut) => { 

        const date = new Date();
        const newHour = date.getHours() + ':' + date.getMinutes();

        if(inOut === 'in') {

            
            setDayIn(newHour);

            setTimeout(() => { 
                setTimeout(() => { refIn.current.focus(); }, 100);
            }, 100);

            //ReactDOM.findDOMNode(myInputRef).focus();
        } else if(inOut === 'out') {

            setTimeout(() => { 
                setTimeout(() => { refOut.current.focus(); }, 100);
            }, 100);

            setDayOut(newHour);
        }
        

    }

    var today = new Date();
    var startTime = today.getHours() + ":" + today.getMinutes();

    return(
        <div className='editHoursItem' >

            <OpenCLoseTextAreaReport 
                    isEdit={true} dayItem={dayItem}
                    selectedReportType={selectedReportType} setSelectedReportType={setSelectedReportType}
                    textAreaReport={textAreaReport} setTextAreaReport={setTextAreaReport}
            />

            <ul className='inOutItems'>
                        
                <li className='in'>

                    <span className={dayIn ? '' : 'error'} >שעת כניסה</span>
                    
                    { dayIn ? <input className='changeHour' type='time' value={dayIn ? dayIn : startTime} ref={refIn} onChange={(e) => setDayIn(e.target.value)} /> : 
                        <p onClick={()=>  setInputFocus('in')} className='error'>--:--</p>
                    }

                </li>
                <li className='separate'>-</li>
                <li  className='out'>
                    <span className={dayOut ? '' : 'error'} >שעת יציאה</span> 

                    { dayOut ? <input className='changeHour' type='time' ref={refOut} value={dayOut ? dayOut : startTime} onChange={(e) => setDayOut(e.target.value)} /> : 
                        <p onClick={()=>  setInputFocus('out')} className='error'>--:--</p>
                    }

                </li>
                
            </ul>
        </div>
    );

}

function ShowInfo(props) {

    const { monthData, setShowInfo } = props


    return(
        <div className='ShowInfo animate__animated animate__fadeIn'>
            
            <div className='contInfo'>
                <h2>דיווחים באיחור</h2>
                <p>הרשות לשירות לאומי-אזרחי מחייבת כל מתנדב/ת לדווח נוכחות בזמן אמת.</p>
                <p> דיווח שעה שחורגת ב-30 דקות ומעלה ממועד הכניסה/יציאה. בפועל, מוגדר כדיווח באיחור ולא יתקבל.</p>
                <p>בנוסף, לא יתקבל דיווח על שעה עתידית.</p>
                <p>סיבת היעדרות ניתן לדווח לכל אורך היום עצמו, מלבד חופש. מחלה, אותן ניתן לדווח בכל החודש.</p>
                <p>{monthData?.LateReportQuota} פעמים, בכל חודש האגודה להתנדבות מאפשרת לדווח באיחור.</p>
                <p>כל שעת כניסה/יציאה או סיבת היעדרות, נספרת כדיווח אחד.</p>
                <p> במידה וניצלת את מלוא המכסה, הדיווח באיחור לא יתקבל ויש. "לפנות לכז/ת להמשך טיפול.</p>
            </div>

            <button onClick={ ()=>setShowInfo(false) }>
                <span>אישור</span>
            </button>
            
        </div>
    )

}

function deafaultValues(monthData,item) {

    const returnObj = {
        ...monthData,
        Dates: [
            {
                'Times' : [],
                ...item.dayData
            },
            
        ],


    }

    return returnObj


}