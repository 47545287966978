import React, { Component } from 'react'

import ContentWrapper from '../../Components/loginPages/ContentWrapper'
//import { RestUrls } from "../../Components/-Helpers-/config";

import NavRight from './NavRight';
import NavMobile from './NavMobile';
import CloseAppIframe from './closeApp/CloseAppIframe';

export default class UserConsoleIndex extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            changeSayarot : false
        }
    }
    

    changeMenuright = (callBack) => {

        this.setState({ changeSayarot : callBack }, ()=> {

            //console.log(callBack);

        })
        
    }
    

    render() {

        //let bgDesktop = RestUrls.pagesPictures;
        /* style={{backgroundImage : "url('"+bgDesktop+"register/bg.jpg')"}} */

        //console.log(this.props);

        if(this.props.isMobile) {

            return (<div className={`body-container UserConsole Mobile ${this.props?.page}PClass`}>
                <NavMobile  {...this.props} changeSayarot={this.state.changeSayarot} />
                <ContentWrapper {...this.props} changeMenuright={ (callBack) => this.changeMenuright(callBack) } />
                {/* < CloseAppIframe {...this.props} /> */}
            </div>)

        }

        else {

            return (<div className="body-container SystemPages Desktop" >

                <div className="content-wrapper twoColumns">
                        <NavRight {...this.props} changeSayarot={this.state.changeSayarot}  />
                    <div className="mainContent">
                        <ContentWrapper {...this.props} changeMenuright={ (callBack) => this.changeMenuright(callBack) } />
                    </div>
                    {/* < CloseAppIframe {...this.props} /> */}
                </div>
                    
            </div>)

        }
        
    }

}
