import React, { Component } from "react";
import dayjs from "dayjs";
import date from "./../../../img/sherut-leumi/svg/search/resultsIcons/date.svg";
import rakaz from "./../../../img/sherut-leumi/svg/search/resultsIcons/rakaz.svg";
import mekomot from "./../../../img/sherut-leumi/svg/search/resultsIcons/mekomot.svg";
import homeOnly from "./../../../img/sherut-leumi/svg/search/resultsIcons/homeOnly.svg";

export default class ShowDataInRow extends Component {
  render() {
    let item = this.props.item;

    // Convert date strings to dd/mm/yyyy format
    const formattedSyrDay =
      item.SyrDay && item.SyrDay !== "---"
        ? dayjs(item.SyrDay, "YYYYMMDD").format("DD/MM/YYYY")
        : "---";

    const formattedRegStart = item.RegStart
      ? dayjs(item.RegStart, "YYYYMMDD").format("DD/MM/YYYY")
      : "---";

    return (
      <div className="ShowDataInRow">
        <header>
          {item.Name ? <h2>{item.Name}</h2> : <h2>#{item.id}</h2>}
          {item.City?.Value ? <p>{item.City?.Value} </p> : <p></p>}
        </header>

        <ul className="iconsLine">
          {formattedSyrDay !== "---" ? (
            <li>
              <img src={date} alt="" />
              <span>תאריך: </span> <span>{formattedSyrDay}</span>
            </li>
          ) : (
            false
          )}

          <li>
            <img src={rakaz} alt="" />
            <span>רכז/ת: </span> <span>{item.CoordinatorName}</span>
          </li>

          {item.LimMtn !== "901" ? (
            <li>
              <img src={mekomot} alt="" />
              <span>מקומות שנותרו: </span> <span>{item?.FreeCount}</span>
            </li>
          ) : (
            false
          )}

          {item.LimMtn === "901" ? (
            <li>
              <img src={mekomot} alt="" />
              <span>רשימת המתנה</span>
            </li>
          ) : (
            false
          )}

          {item.HomeOnly === "1" ? (
            <li>
              <img src={homeOnly} alt="" />
              <span>ללא דירת שרות</span>
            </li>
          ) : (
            false
          )}
        </ul>
      </div>
    );
  }
}
