import React from "react";

import bulletLev from "../../img/icons/menuIcons/lev.svg";
import userData from "../../img/icons/menuIcons/pratim.svg";
import exit from "../../img/icons/menuIcons/exit.svg";
import mekomotSherut from "../../img/icons/menuIcons/mekomotSherut.svg";
import doccumentIco from "../../img/icons/menuIcons/doccumentIco.svg";
import clockInOutIndex from "../../img/icons/menuIcons/timer.svg";
import contact from "../../img/icons/menuIcons/envelope.svg";

const bulletIcons = {
  bulletLev,
  userData,
  files: doccumentIco,
  logOff: exit,
  sherutPlaces: mekomotSherut,
  clockInOutIndex,
  contact,
};

const BulletsMenu = ({ name, sayarotNumber }) => {
  const icon = bulletIcons[name];

  if (!icon) return null;

  return (
    <div className="BulletMenuCont">
      <div className="bCountCont">
        {name === "bulletLev" && <div className="number">{sayarotNumber}</div>}
        <img className="bulletMainMenu" src={icon} alt={`${name} icon`} />
      </div>
    </div>
  );
};

export default BulletsMenu;
