import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; //npm install react-router-dom
import PrintView from "../../Pages/PrintView/PrintView";
import UserConsoleIndex from "../../Pages/UserConsole/UserConsoleIndex";

const PagesUserConsole = (props) => {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route
            exact
            path="/userConsole/search"
            render={() => <UserConsoleIndex {...props} page="userSearch" />}
          />
          <Route
            exact
            path="/userConsole/data"
            render={() => <UserConsoleIndex {...props} page="userData" />}
          />
          <Route
            exact
            path="/userConsole/files"
            render={() => <UserConsoleIndex {...props} page="files" />}
          />
          <Route
            exact
            path="/userConsole/printview"
            render={() => <PrintView {...props} page="files" />}
          />
          <Route
            exact
            path="/userConsole/clockInOut"
            render={() => <UserConsoleIndex {...props} page="clockInOutIndex" />}
          />
          <Route
            exact
            path="/userConsole/training"
            render={() => <UserConsoleIndex {...props} page="training" />}
          />
          <Route
            exact
            path="/userConsole/sorties"
            render={() => <UserConsoleIndex {...props} page="sorties" />}
          />
          <Route
            exact
            path="/userConsole/editAvatar"
            render={() => <UserConsoleIndex {...props} page="editAvatar" />}
          />
          <Route
            exact
            path="/userConsole/contactUs"
            render={() => <UserConsoleIndex {...props} page="contactUs" />}
          />
          <Route
            path="/"
            render={() => <UserConsoleIndex {...props} page="userIndex" />}
          />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default PagesUserConsole;
