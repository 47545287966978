import React, { Component } from "react";
//import loader from "../../../img/preLoader.gif";

import { Form, FormControl, Button } from "react-bootstrap";
import getDataFromApi from "../../../Components/-Helpers-/api/getDataFromApi";
import ShowFilters from "./ShowFilters";

export default class SearchUp extends Component {
  constructor(props) {
    
    super(props);

    this.state = {
      searchDataResp: null,
      loading: false,

      freeSearch: "",
      rakaz: "",
      city: "",
      sayeretId:  this.props.currentState.sayeretId ? this.props.currentState.sayeretId : '',

      updateFilters:  false,
    };

    //console.log(this.state);
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      //console.log(this.state);
    });
  };

  componentDidMount() {
    console.log(this.props, "this.state cities")
    if (!this.state.searchDataResp ||  this.props.currentState.sayeretId ) {
      this.sendtoAdmin(
        "getSayarotSearchParams",
        "sayarot",
        {},
        "searchDataResp",
        "sayarot"
      );
    }


    if(this.props.currentState.sayeretId) {

      this.setState({
        sayeretId:  this.props.currentState.sayeretId,
        updateFilters: Date.now(),
      });

    }


  }

  sendtoAdmin = (
    url,
    controller,
    objectToSend,
    setStateName = "data",
    auth = "all"
  ) => {
    console.log("OBJECTTOSEND",objectToSend);
    this.setState({ loading: true });

    const getData = getDataFromApi(url, objectToSend, controller, auth);

    getData.then((getData) =>
      this.setState({ [setStateName]: getData, loading: false }, () => {
        if (url === "getSayarotSearchParams" && getData && getData.rakazot) {
          if (this.props.currentState.rakaz) {
            this.setState({
              rakaz: this.props.currentState.rakaz,
              sayeretId:  this.props.currentState.sayeretId,
              updateFilters: Date.now(),
            });
          }
        }
      })
    );
  };

  sendForm = () => {
    this.setState({
      updateFilters: Date.now(),
    });

    //console.log(this.state);
    this.props.updateSearch(this.state);
  };

  handleChangeSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value }, function () {
      //console.log(this.state)
    });
  };

  searchFilters = (param) => {
    this.setState(
      {
        [param]: "",
      },
      () => {
        this.sendForm();
      }
    );
  };

  render() {
    //let flagSend = this.state.city || this.state.rakaz || this.state.freeSearch ? true : false;
    let flagSend = true;

    return (
      <div className="SearchAndResults">
        <div className="SearchUpCont GreyPanel">
          {/* <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } /> */}

          {this.state.searchDataResp ? (
            <div>
              <div className="line">
                <Form.Group className="inputsWithLabel searchIco">
                  <Form.Label>חיפוש חופשי</Form.Label>
                  <FormControl
                    placeholder="שם הסיירת, תיאור"
                    className="inputsWithLabel"
                    onChange={this.handleChange}
                    name="freeSearch"
                    value={this.state.freeSearch}
                    type="text"
                  />
                </Form.Group>
              </div>

              <div className="line seccondLine">
                <Form.Group className="inputsWithLabel selectIco2 rakazIco iconAfter2">
                  <Form.Label>לפי רכזת</Form.Label>

                  <FormControl
                    as="select"
                    className="inputsWithLabel selectIcoBG"
                    onChange={this.handleChangeSelect}
                    name="rakaz"
                    value={this.state.rakaz}
                  >
                    <option value="">בחרו רכז/ת</option>
                    {/* <option value="">כל הרכזות</option> */}
                    <option disabled>----</option>

                    {this.state.searchDataResp.rakazot && this.state.searchDataResp.rakazot.map((item) => {
                      if (true) {
                        /* currentState={this.state} */
                        return (
                          <option value={item.Rak_Key} key={item.Rak_Key}>
                            {item.Rak_Value}
                          </option>
                        );
                      } else return false;
                    })}
                  </FormControl>
                </Form.Group>

                <Form.Group className="inputsWithLabel selectIco2 placeIco iconAfter2">
                  <Form.Label>לפי ישוב</Form.Label>

                  <FormControl
                    as="select"
                    className="inputsWithLabel selectIcoBG"
                    onChange={this.handleChangeSelect}
                    name="city"
                    value={this.state.city}
                  >
                    <option value="">בחרו ישוב</option>
                    {/* <option value="">כל הישובים</option> */}
                    <option disabled>----</option>

                    {this.state.searchDataResp.cities && this.state.searchDataResp.cities.map((item) => {
                      if (true) {
                        /* currentState={this.state} */
                        return (
                          <option value={item.City_Key} key={item.City_Key}>
                            {item.City_Value}
                          </option>
                        );
                      } else return false;
                    })}
                  </FormControl>
                </Form.Group>

                <Button
                  disabled={!flagSend ? true : false}
                  className="sendBlueBtn withDisabled"
                  onClick={this.sendForm}
                  size="lg"
                  variant={
                    flagSend
                      ? "success animate__animated animate__bounceIn"
                      : "disabled"
                  }
                >
                  חיפוש
                </Button>
              </div>
            </div>
          ) : (
            false
          )}
        </div>


        {(this.state.freeSearch || this.state.rakaz || this.state.city || this.state.sayeretId ) &&
        this.state.updateFilters ? (
          <ShowFilters
            updateFilters={this.state.updateFilters}
            searchData={this.state.searchDataResp}
            currentState={this.state}
            callBack={(stateName) => this.searchFilters(stateName)}
          />
        ) : (
          false
        )}
      </div>
    );
  }
}
