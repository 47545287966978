import React, { Component } from 'react'


import {Button} from 'react-bootstrap' //npm install react-bootstrap@next bootstrap@5.1.0

import getDataFromApi from '../../../Components/-Helpers-/api/getDataFromApi';
import loader from "../../../img/preLoader.gif";

import CustomFloatInput from '../../../Components/-Helpers-/forms/CustomFloatInput';
//import FloatInputPassword from '../../../Components/-Helpers-/forms/FloatInputPassword';

import ModalDefaul from '../../../Components/-Helpers-/ModalDefaul';
import { toast } from 'react-toastify';
import { isEmpty, map } from 'lodash';

export default class Studies extends Component {

    constructor(props) {

        super(props);

        let user = JSON.parse(localStorage.getItem('userData'));
        

        let userApi = this.props.userData;
    
        console.log("haimmmmm", userApi);

        this.state = {
            idno :  user.IDNO,
            SessionKey : user.SessionKey,

            
            PrvSchool : userApi.prvschool,
            PrvSchoolText : userApi.PrvSchoolText,

            sh_year1 : userApi.sh_year1,
            

            loading : false,
            btnSendClass : 'success',
            responseEditRegister : false,
            years : []
        }

        console.log(this.state);
    
    
    
    }

    async getPrvSchollTxt(key) {
        try {
            const x = await getDataFromApi('getSchools4Select', {}, 'app', 'all');
            const options = x.options;
            const foundObj = options.find(obj => obj.Key === key);

            if (foundObj) {
                this.setState({ PrvSchoolText: foundObj.name });
                return foundObj.name;
            } else {
                return 'Object not found';
            }
        } catch (error) {
            console.error('Error:', error);
            return 'error';
        }
    }

    async componentDidMount() {
        if (this.state.PrvSchool) {
            const getPrvSchollTxt = await this.getPrvSchollTxt(this.state.PrvSchool);
            this.setState({ getPrvSchollTxt });
        }

        if(this.props.userData.years) {

            let years = [];

            map(this.props.userData.years, item => {

                years.push(
                    {
                        id:item.id.substring(1),
                        value:item.value.substring(1)
                    }
                );

            })

            this.setState({years : years})

        }
        

    }


    sendForm = (sendRules) => {


        this.setState({
            checkInputs : true,
            checkInputsPage : true
        })

        if(sendRules) {

            this.setState({
                loading: true
            });

            const sendObj = this.state;delete sendObj.responseEditRegister;
            delete sendObj.loading;delete sendObj.btnSendClass;
            delete sendObj.checkInputs;delete sendObj.checkInputsPage;



            this.sendtoAdmin('editUserData','users',sendObj,'responseEditRegister','users');

            //console.log(sendObj);
            
        } else {

            //console.log('NO send');
            //console.log(sendRules)
            toast.error('נא למלא שדות חובה');

            this.setState({
                btnSendClass : 'danger'
            })
        }

        

    }

    sendtoAdmin = (url,controller,objectToSend, setStateName = 'data', auth = 'all') => {

        this.setState({loading : true});

        const getData = getDataFromApi(url,objectToSend,controller,auth);

        getData.then(getData =>this.setState({ [setStateName] : getData, loading : false}, () => {

            //console.log(getData);

        }))
    }


    
    updateValue = (newValue) => {

        this.setState(newValue,()=>{
            //console.log(this.state);
        })

        //console.log('newValue', newValue);

        
    }


    render() {

        const sendRules = 
            this.state.PrvSchool &&
            this.state.sh_year1
        ;

        //console.log(sendRules);

        //const isMobile = this.props.isMobile ? this.props.isMobile : false;

        return (
            <div>
                <div className="firstRegister animate__animated animate__fadeIn">

                <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                <div className="inputs">

                    <div className="lineLabel">
                        <label style={{fontSize : '13px',color:'grey'}} >בית הספר בו למדתי</label>
                    </div>

                    <div className="rows2  clear line">
                        {console.log("maaaa", this.state)}

                        {this.state.PrvSchool ? 
                        <CustomFloatInput disabled={true} name='relativename' updateValue={this.updateValue} value={this.state.PrvSchoolText} placeholder={this.state.PrvSchoolText} cssClass='' validationRules={ {required: true } } typeInput='text' checkInputs={this.state.checkInputs} checked={()=>this.setState({ checkInputs : false })} />
                        :
                         <CustomFloatInput name='PrvSchool' dbParams={{ function : 'getSchools4Select', controller : 'app', valueField : 'id' }} updateValue={this.updateValue} value={this.state.PrvSchool} placeholder={this.state.PrvSchool ? this.state.PrvSchoolText : "בחר/י בית ספר"} cssClass='' validationRules={ {required: true} } typeInput="searchDB" checkInputs={this.state.checkInputs} checked={()=>this.setState({ checkInputs : false })}/>
                        }
                        
                        {/* <CustomFloatInput name='sh_year1' updateValue={this.updateValue} value={this.state.sh_year1} placeholder="שנת סיום לימודים" cssClass='' validationRules={ {required: true } } typeInput='text' checkInputs={this.state.checkInputs} checked={()=>this.setState({ checkInputs : false })} /> */} 

                        {!isEmpty(this.state.years) && <CustomFloatInput name="sh_year1" selectOptions={this.state.years} disabled={true} updateValue={this.updateValue} value={this.state.sh_year1} placeholder="שנת סיום לימודים" cssClass="" validationRules={{ required: true }} typeInput="select" checkInputs={this.state.checkInputs} checked={() => this.setState({ checkInputs: false })} /> }

                    </div>


                    <div className="text-center" >
                        <br/>
                        {/* disabled={!flagSendForm} */}
                        <Button className="sendBlueBtn" onClick={() => this.sendForm(sendRules)} size="lg" variant={sendRules ? 'success animate__animated animate__bounceIn' : this.state.btnSendClass} >שמירת שינויים</Button>

                    </div>

                </div>


                {this.state.responseEditRegister && this.state.responseEditRegister.error ? 

                < ModalDefaul variant="error" params={ {title: 'שגיאה', text : this.state.responseEditRegister.error } } callBack={() => this.setState({ responseEditRegister : false })}  />

                : false }

                {this.state.responseEditRegister && this.state.responseEditRegister.ok ? 

                < ModalDefaul params={ {title: 'תודה רבה', text : this.state.responseEditRegister.ok } } callBack={() => this.setState({ responseEditRegister : false })}  />

                : false }

                </div>
            </div>
        )
    }
}
