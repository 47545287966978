import React, { Component } from "react";
import { RestUrls } from "../../../Components/-Helpers-/config";
import ShowDataInRow from "./ShowDataInRow";
import ShowMoreData from "./ShowMoreData";
import ModalDeleteSayeret from "./ModalDeleteSayeret";
import { Button } from "react-bootstrap";
import lev from "../../../img/sherut-leumi/svg/search/lev.svg";
import lev2 from "../../../img/sherut-leumi/svg/search/lev2.svg";
import getDataFromApi from "../../../Components/-Helpers-/api/getDataFromApi";
import loader from "../../../img/preLoader.gif";
import ModalDefaul from "../../../Components/-Helpers-/ModalDefaul";
import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect
import dayjs from "dayjs";

export default class SearchResultRow extends Component {
  constructor(props) {
    super(props);

    const item = this.props.item;
    let user = JSON.parse(localStorage.getItem("userData"));

    let checkActive = user.Sayarot.includes(item.id) ? true : false;

    this.state = {
      showMoreData: this.props.activeSayeretId === item.id ? true : false,
      isActive: checkActive,
      deleteSayeret: false,
      sayarotActiveResp: false,
      errorModal: false,
    };
  }

  send = (id) => {
    if (this.state.isActive) {
      this.setState({
        deleteSayeret: true,
      });
    } else {
      this.changeUserSayarot();
    }
  };

  changeUserSayarot = () => {
    let user = JSON.parse(localStorage.getItem("userData"));

    //console.log(this.props.changeSayarot);

    let sendObj = {
      IDNO: user.IDNO,
      SessionKey: user.SessionKey,
      sayarId: this.props.item.id,
    };

    this.sendtoAdmin(
      "changeSayarotUser",
      "sayarot",
      sendObj,
      "sayarotActiveResp",
      "sayarot"
    );
  };

  sendtoAdmin = (
    url,
    controller,
    objectToSend,
    setStateName = "data",
    auth = "all"
  ) => {
    this.setState({ loading: true });
    // console.log("הרשמה",url);

    const getData = getDataFromApi(url, objectToSend, controller, auth);

    getData.then((getData) =>
      this.setState({ [setStateName]: getData, loading: false }, () => {
        //console.log(getData);

        if (getData.error) {
          this.setState({
            errorModal: { error: getData.error },
          });
        } else {
          this.setState({ isActive: !this.state.isActive });
          //console.log(getData);

          let user = JSON.parse(localStorage.getItem("userData"));
          user["Sayarot"] = getData.newArray;
          localStorage.setItem("userData", JSON.stringify(user));

          //console.log(this.props);

          const sendObj = {
            changeSayeret: Date.now(),
            count: getData.newArray.length,
            id: this.props.item.id,
            isActive: this.state.isActive,
            name: this.props.item.Name ? '"' + this.props.item.Name + '"' : " ",
          };

          this.props.changeMenuright(sendObj);
        }
      })
    );
  };

  handleDeleteSayerey = (param, id) => {
    if (param) {
      this.changeUserSayarot();
    } else {
      console.log("CANCEL");
    }
  };

  render() {
    const item = this.props.item;
    let urlPic =
      RestUrls.pagesPictures + "/search/randomPic/" + item.randPic + ".jpg";

    console.log("item talk with saheem", item);

    let btnText = "";
    let notShowIcon = false;

    if (this.state.isActive) {
      btnText = "הסר הרשמה";
    } else if (item.RegStart !== "0") {
      const formattedDate = dayjs(item.RegStart).format("DD/MM/YYYY");
      btnText = "ההרשמה תחל ב-" + formattedDate;
    } else if (item.outdated === "1") {
      btnText = "ההרשמה נגמרה";
      notShowIcon = true;
    } else if (parseInt(item.placesLeft) === 0) {
      btnText = "המקומות אזלו";
      notShowIcon = true;
    } else {
      btnText = "הרשמה";
    }

    //console.log(item);

    return (
      <div className="searchRow  GreyPanel">
        <img
          src={loader}
          alt="loader"
          className={
            !this.state.loading
              ? "loader"
              : "loader active animate__animated animate__fadeIn"
          }
        />

        <div className="tableSearchRow">
          <div className="coltableSearch picCol">
            <figure style={{ backgroundImage: "url('" + urlPic + "')" }} />
          </div>

          <div className="coltableSearch dataCol">
            <ShowDataInRow item={item} />
          </div>

          {!isMobile ? (
            <div className="coltableSearch BtnsCol">
              <div className="buttonsCont">
                <Button
                  className={
                    this.state.isActive ? "sendBlueBtn  actived" : "sendBlueBtn"
                  }
                  onClick={() => this.send(item.id)}
                  disabled={
                    item.outdated === "1" ||
                    item.notStartedReg !== "0" ||
                    (!this.state.isActive && parseInt(item.placesLeft) === 0)
                      ? true
                      : false
                  }
                  size="sm"
                >
                  {!notShowIcon ? (
                    <img src={this.state.isActive ? lev2 : lev} alt="" />
                  ) : (
                    false
                  )}
                  <span>{btnText}</span>
                </Button>

                <button
                  className="showMoreData"
                  onClick={() =>
                    this.setState({ showMoreData: !this.state.showMoreData })
                  }
                >
                  {!this.state.showMoreData ? "לפרטים נוספים" : "סגירה"}
                </button>
              </div>
            </div>
          ) : (
            false
          )}
        </div>

        {isMobile ? (
          <div className="BtnsCol">
            <div className="buttonsCont">
              <Button
                className={
                  this.state.isActive ? "sendBlueBtn  actived" : "sendBlueBtn"
                }
                onClick={() => this.send(item.id)}
                disabled={
                  item.outdated === "1" ||
                  item.notStartedReg !== "0" ||
                  (!this.state.isActive && parseInt(item.placesLeft) === 0)
                    ? true
                    : false
                    ? true
                    : false
                }
                size="sm"
              >
                {!notShowIcon ? (
                  <img src={this.state.isActive ? lev2 : lev} alt="" />
                ) : (
                  false
                )}
                <span>{btnText}</span>
              </Button>

              <button
                className="showMoreData"
                onClick={() =>
                  this.setState({ showMoreData: !this.state.showMoreData })
                }
              >
                {!this.state.showMoreData ? "לפרטים נוספים" : "סגירה"}
              </button>
            </div>
          </div>
        ) : (
          false
        )}

        {this.state.showMoreData ? (
          <div className="showMoreData animate__animated animate__fadeIn">
            <ShowMoreData item={item} />
          </div>
        ) : (
          false
        )}

        {this.state.deleteSayeret ? (
          <ModalDeleteSayeret
            item={item}
            modalShow={(value) => this.setState({ deleteSayeret: value })}
            callBack={(param, id) => this.handleDeleteSayerey(param, id)}
          />
        ) : (
          false
        )}

        {this.state.errorModal && this.state.errorModal.error ? (
          <ModalDefaul
            variant="error"
            params={{ title: "שגיאה", text: this.state.errorModal.error }}
            callBack={() => this.setState({ errorModal: false })}
          />
        ) : (
          false
        )}
      </div>
    );
  }
}
