import React, { Component } from "react";
import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect

import place from "./../../../img/sherut-leumi/svg/search/resultsIcons/place.svg";
import phone from "./../../../img/sherut-leumi/svg/search/resultsIcons/phone.svg";
import clock from "./../../../img/sherut-leumi/svg/search/resultsIcons/clock.svg";
//import kaal from "./../../../img/sherut-leumi/svg/search/resultsIcons/kaal.svg";

import mosdot from "./../../../img/sherut-leumi/svg/search/resultsIcons/mosdot.svg";
import agaa from "./../../../img/sherut-leumi/svg/search/resultsIcons/agaa.svg";
//import pratim from "./../../../img/sherut-leumi/svg/search/resultsIcons/pratim.svg";

export default class ShowMoreData extends Component {
  render() {
    const item = this.props.item;

    let Notes = item.Notes;

    if (item.Notes && item.Info) {
      Notes = Notes + ", ";
    }

    return (
      <div>
        <h3>פרטים נוספים:</h3>

        {item.Info || item.Notes ? (
          <article>{Notes + item.Info}</article>
        ) : (
          false
        )}

        <ul className={isMobile ? "iconsLine" : "iconsLine twoColums"}>
          <li>
            <img src={phone} alt="" />
            <span>טלפון רכז/ת: </span> <span>{item.CoordinatorPhone}</span>
            {isMobile ? (
              <a className="callBtn" href={"tel:" + item.Rak?.Mobile}>

                חייגו &raquo;
              </a>
            ) : (
              false
            )}
          </li>

          {item.ArriveTo ? (
            <li>
              <img src={place} alt="" />
              <span>מקום מפגש: </span> <span>{item.ArriveTo}</span>
            </li>
          ) : (
            false
          )}

          {item.ArriveAt ? (
            <li>
              <img src={clock} alt="" />
              <span>שעת מפגש: </span> <span>{item.ArriveAt}</span>
            </li>
          ) : (
            false
          )}

          {/* {item.Notes ? <li>
                        <img src={kaal} alt='' />
                        <span>קהל יעד: </span> <span>{item.Notes}</span>
                    </li> : false } */}

          {item.Mosadot ? (
            <li>
              <img src={mosdot} alt="" />
              <span>מוסדות: </span> <p>{item.Mosadot}</p>
            </li>
          ) : (
            false
          )}

          {item.ArriveNotes ? (
            <li>
              <img src={agaa} alt="" />
              <span>דרכי הגעה: </span> <span>{item.ArriveNotes}</span>
            </li>
          ) : (
            false
          )}
        </ul>
      </div>
    );
  }
}
